import { Option } from "api/types/_public";

export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "7",
  },
];

export const TYPE_SHOW_MORE_HASHTAG = {
  SCROLL: "scroll",
  MODAL: "modal",
};

export const COLORS_CONFIGURATION_STATUS = ["danger", "success"];

export const COLORS_STATUS_CAMPAIGN: any = {
  pending: "secondary",
  approved: "primary",
  completed: "success",
  rejected: "danger",
};

export const TRANSMISSION_TYPE: Option[] = [
  {
    label: "Media",
    value: "media",
  },

  {
    label: "Package",
    value: "package",
  },
];

export const formatNumber = (number: any) => {
  if (number >= 1000) {
    const formattedNumber = new Intl.NumberFormat().format(number);
    return formattedNumber;
  } else {
    return number.toString();
  }
};

export const COLORS_NEWS_STATUS = [
  "danger",
  "success",
  "warning",
  "secondary",
  "primary",
  "dark",
];

export const STATUS_CAMPAIGN_OPTIONS: Option[] = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "Published",
    value: "published",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
];

export const STATUS_INQUIRY_OPTIONS: Option[] = [
  {
    label: "Pending",
    value: "pending",
  },

  {
    label: "In progress",
    value: "in_progress",
  },
  {
    label: "Completed",
    value: "completed",
  },
];

export const PAYMENT_METHOD_OPTIONS: Option[] = [
  {
    label: "Card",
    value: "card",
  },
  {
    label: "Bank Transfer",
    value: "bank_transfer",
  },
];

export const DATE_TYPE_OPTIONS: Option[] = [
  {
    label: "Created at",
    value: "created_at",
  },
  {
    label: "Published at",
    value: "published_at",
  },
  {
    label: "Reserved date",
    value: "reserved_date",
  },
];

export const HELP_TYPE_OPTIONS: Option[] = [
  {
    label: "Usage",
    value: "usage",
  },
  {
    label: "F&Q",
    value: "faq",
  },
];
