//Include Both Helper File with needed methods
import { createAsyncThunk } from "@reduxjs/toolkit";
import { postJwtProfile } from "../../../helpers/fakebackend_helper";

// action
import authApi from "api/authApi";
import { formatQueryParams } from "helpers/format";
import {
  profileError,
  profileSuccess,
  resetProfileFlagChange,
} from "./reducer";

export const getProfile = createAsyncThunk(
  "Profile",
  async (params: any = {}) => {
    try {
      const response = await authApi.profile(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const editProfile = (user: any) => async (dispatch: any) => {
  try {
    let response;

    response = postJwtProfile({
      username: user.username,
      idx: user.idx,
    });

    const data = await response;

    if (data) {
      dispatch(profileSuccess(data));
    }
  } catch (error) {
    dispatch(profileError(error));
  }
};

export const resetProfileFlag = () => {
  try {
    const response = resetProfileFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};
