import { createAsyncThunk } from "@reduxjs/toolkit";
import keywordApi from "api/keywordApi";
import { formatQueryParams } from "helpers/format";

//Include Both Helper File with needed methods

export const getKeywords = createAsyncThunk(
  "Keyword",
  async (params: any = {}) => {
    try {
      const response = await keywordApi.keywords(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);
