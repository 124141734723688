import inquiryApi from "api/inquiryApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import FeatherIcon from "feather-icons-react";
import { useFormik } from "formik";
import { STATUS_INQUIRY_OPTIONS } from "helpers/constans";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import { postHelp, putHelp } from "store/help/thunk";
import * as Yup from "yup";
import CommentList from "./CommentList";
import uploadFileApi from "api/uploadFileApi";
const COLORS_STATUS: any = {
  pending: "warning",
  in_progress: "primary",
  completed: "success",
};
const ModalFormUser = ({
  onCloseClick,
  initialValuesDefault,
  show,
  refreshData,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [isLoadingComment, setIsLoadingComment] = useState<boolean>(false);
  const validationSchema = Yup.object({
    title: Yup.string().required(`${t("Title is required")}`),

    attachment: Yup.mixed().required(`${t("Attachment is required")}`),
  });
  const refContent = useRef<any>(null);
  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data: any = {
        title: values?.title ?? "",
        content: refContent?.current?.getContent() ?? "",
        attachment: values?.attachment ?? null,
      };
      if (values?.attachment?.name) {
        const formData = new FormData();
        formData.append("files", data?.attachment);
        const res: any = await uploadFileApi.uploadImage(formData);
        if (res?.code === 200) {
          const params: any = {
            title: values?.title ?? "",
            content: refContent?.current?.getContent() ?? "",
            attachments: [
              {
                path: res?.data[0]?.path ?? null,
                type:
                  res?.data[0]?.mimetype === "image/png" ? "image" : "image",
              },
            ],
          };
          handleSubmitData(params);
        }
      }
      if (!values?.attachment?.name) {
        delete data?.attachment;
        handleSubmitData(data);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast.error(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleSubmitData = async (params: any) => {
    const response: any = !!data?.id
      ? await putHelp(data?.id, params)
      : await postHelp(params);
    if (response.code === 200) {
      refreshData();
      setIsLoading((_prev) => false);
      handleOnClose();
      toast.success(
        `${t("The process has been completed.")}`,
        CONFIG_OPTION_TOAST_NORMAL
      );
      // handleResetForm();
    } else {
      setIsLoading((_prev) => false);
      toast.error(`${response}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  const handleOnClose = () => {
    formik.setValues({
      title: "",
      content: "",
      attachment: {},
    });
    formik?.setErrors({});

    onCloseClick();
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
      attachment: {},
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleUpdateStatus = async (value: any) => {
    try {
      setIsLoadingUpdate((_prev) => true);
      const param = { status: value.value };
      const res: any = await inquiryApi.updateStatus(data?.id, param);
      if (res?.code === 200) {
        toast.success(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        getDetailInquiry(initialValuesDefault?.id);
        refreshData();
      } else {
        toast.error(`${res?.message}`, CONFIG_OPTION_TOAST_ERROR);
      }
      setIsLoadingUpdate((_prev) => false);
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast.error(
        `${error?.message || error || ""} `,
        CONFIG_OPTION_TOAST_ERROR
      );
    }
  };

  const getDetailInquiry = async (id: any) => {
    try {
      const res: any = await inquiryApi.getDetail(id);
      if (res?.code === 200) {
        setData(res?.data);
      } else {
        toast.error(`${res?.message}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast.error(
        `${error?.message || error || ""} `,
        CONFIG_OPTION_TOAST_ERROR
      );
    }
  };

  const handlePostComment = async (data: any) => {
    try {
      setIsLoadingComment(true);
      const res: any = await inquiryApi.postComment(initialValuesDefault?.id, {
        content: data,
      });
      if (res?.code === 200) {
        toast.success(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        getDetailInquiry(initialValuesDefault?.id);
      } else {
        toast.error(`${res?.message}`, CONFIG_OPTION_TOAST_ERROR);
      }
      setIsLoadingComment(false);
    } catch (error: any) {
      toast.error(
        `${error?.message || error || ""} `,
        CONFIG_OPTION_TOAST_ERROR
      );
      setIsLoadingComment(false);
    }
  };

  useEffect(() => {
    formik.setValues({
      title: data?.title || "",
      content: data?.content || "",
      attachment: data?.attachment || {},
    } as any);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    setIsOpen(show);
    return () => setIsOpen(false);
  }, [show]);
  useEffect(() => {
    if (show && initialValuesDefault?.id) {
      getDetailInquiry(initialValuesDefault?.id);
    }
  }, [show, initialValuesDefault]);
  return (
    <Modal isOpen={isOpen} centered={true} size="xl">
      <ModalHeader toggle={isLoading ? () => { } : handleOnClose}>
        {t("Inquiry info")}
      </ModalHeader>
      <ModalBody className="">
        <div className="row">
          <div className="col-lg-12">
            <div className="mx-n3 mb-n5 border-0 card ">
              <div className="bg-primary-subtle">
                <div
                  className={` infinite-loading-outer ${isLoadingUpdate && "table-custom-pagination is-show-loading"
                    }`}
                >
                  <div className="infinite-loading-inner"></div>
                </div>
                <div className="pb-4 mb-5 card-body">
                  <div className="row">
                    <div className="col-md">
                      <div className="align-items-center row">
                        <div className="col-md-auto">
                          <div className="avatar-md mb-md-0 mb-4">
                            <div className="avatar-title bg-white rounded-circle">
                              {data?.attachments?.length > 0 && (
                                <img
                                  src={data?.attachments[0]?.url}
                                  alt=""
                                  className="avatar-sm border rounded-circle"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md">
                          <h4 className="fw-semibold">
                            #{data?.id} - {data?.title}
                          </h4>
                          <div className="hstack gap-3 flex-wrap">
                            <div className="text-muted d-flex justify-content-center align-items-center">
                              <FeatherIcon
                                icon="tv"
                                className="icon-dual me-2 text-primary"
                                style={{ width: 16, height: 16 }}
                              />
                              {data?.advertiser?.business_name}
                            </div>
                            <div className="vr" />
                            <div className="text-muted">
                              {t('Created at')} :{" "}
                              <span className="fw-medium">
                                {data?.created_at}
                              </span>
                            </div>
                            <div className="vr" />
                            <div className="text-muted">
                              {t('Updated at')} :{" "}
                              <span className="fw-medium">
                                {data?.updated_at}
                              </span>
                            </div>
                            <div className="vr" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row h-100">
          <div className="col-xxl-4">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">{t("Inquiry Details")}</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive overflow-visible table-card">
                  <table className="table-borderless align-middle mb-0 table">
                    <tbody>
                      <tr>
                        <td className="fw-medium">{t("Inquiry")}</td>
                        <td>#{data?.id} </td>
                      </tr>
                      <tr>
                        <td className="fw-medium">{t("Advertiser")}</td>
                        <td id="t-client">{data?.advertiser?.business_name}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">{t("Campaign")}</td>
                        <td>{data?.campaign?.name}</td>
                      </tr>

                      <tr>
                        <td className="fw-medium ">{t("Status")}:</td>
                        <td>
                          <Select
                            options={STATUS_INQUIRY_OPTIONS}
                            value={
                              STATUS_INQUIRY_OPTIONS?.filter(
                                (item: any) =>
                                  String(item?.value) === String(data?.status)
                              )[0]
                            }
                            onChange={(e: any) => handleUpdateStatus(e)}
                            placeholder={<div>{"Status"}</div>}
                            loadingMessage={() => <div>{t("Loading")}...</div>}
                            noOptionsMessage={() => (
                              <div>{t("No Options")}</div>
                            )}
                            className="search-filter-media"
                            classNamePrefix="name-prefix"
                            formatOptionLabel={(option: any, index: number) => (
                              <div className="d-flex align-items-center">
                                <span
                                  className={`bg-${option?.value !== ""
                                    ? COLORS_STATUS[option?.value]
                                    : "secondary"
                                    }`}
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "3px",
                                  }}
                                ></span>
                                <div
                                  className={`ms-2 text-${COLORS_STATUS[option?.value]
                                    }`}
                                >
                                  {option?.label}
                                </div>
                              </div>
                            )}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className="fw-medium">{t("Create Date")}</td>
                        <td>{data?.created_at}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">{t("Update Date")}</td>
                        <td>{data?.updated_at}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {data?.attachments?.length > 0 && (
              <div className="card">
                <div className="card-header">
                  <h6 className="card-title fw-semibold mb-0">
                    {t("Files Attachment")}
                  </h6>
                </div>
                <div className="card-body">
                  <div className="d-flex gap-2 w-auto align-items-center border border-dashed p-2 rounded">
                    {data?.attachments?.length > 0 &&
                      data?.attachments?.map((item: any) => (
                        <div className="p-2">
                          <div className="flex-shrink-0 avatar-sm">
                            <div className="avatar-title bg-light rounded">
                              <img
                                alt=""
                                src={item?.url}
                                style={{ width: 50, maxHeight: 100 }}
                              ></img>
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-3"></div>
                          <div className="hstack gap-3 fs-16">
                            <i className="ri-download-2-line text-muted" />

                            <i className="ri-delete-bin-line text-muted" />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-xxl-8 h-100">
            <div className="card h-100">
              <div className="p-4 card-body h-100">{data?.content}</div>
            </div>
            <CommentList
              data={data?.replies}
              handlePostComment={handlePostComment}
              isLoading={isLoadingComment}
            />
          </div>
        </div>

        <div className="row g-4">
          <Col className="order-sm-1 order-md-2">
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-3">
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      className="btn btn-light fs-14"
                      type="button"
                      color="light"
                      onClick={handleOnClose}
                      disabled={isLoading}
                    >
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t("Button Close")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Col>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalFormUser;
