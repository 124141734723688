import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatusNews from "components/Common/DropdownStatusNews";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import {
  COLORS_NEWS_STATUS,
  DATE_TYPE_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
  STATUS_CAMPAIGN_OPTIONS,
  formatNumber,
} from "helpers/constans";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {
  deleteCampaign,
  getAllMedias,
  getCampaigns as onGetCampaigns,
} from "../../../store/thunks";
import CampaignFormMulti from "../CampaignCreate/components/CampaignFormMulti";
const TYPE_SELECT_DEFAULT: string = "reserved_date";

const CampaignList = () => {
  const { t, i18n } = useTranslation();
  const STATUS_CAMPAIGN_OPTIONS_LANG = STATUS_CAMPAIGN_OPTIONS?.map(
    (item: any) => ({ value: item?.value, label: t(item?.label) })
  );

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    keyword: StringParam,
    end_date: withDefault(StringParam, ""),
    start_date: withDefault(StringParam, ""),
    status: withDefault(StringParam, ""),
    media_id: StringParam,
    filter_date_by: StringParam,
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
  });
  const [initialValuesDefault, setInitialValuesDefault] = useState<any | null>(
    null
  );
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [dataPreview, setDataPreview] = useState<any>(null);
  const [userEdit, setUserEdit] = useState<(any & { isCopy?: boolean }) | null>(
    null
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenMediasDetail, setIsOpenMediasDetail] = useState<boolean>(false);
  const [medias, setMedias] = useState<any[]>([]);
  const [allMedias, setAllMedias] = useState<any[]>([]);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [keyword, setKeyword] = useState("");
  const [mediaSearch, setMediaSearch] = useState<any>();
  const [statusSearch, setStatusSearch] = useState<any | null>(
    STATUS_CAMPAIGN_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.status)
    )[0]
  );
  const [dateTypeSearch, setDateTypeSearch] = useState<any | null>(
    DATE_TYPE_OPTIONS[0]
  );

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Campaign;
  const campaignProperties = createSelector(selectLayoutState, (state) => ({
    campaigns: state.campaigns,
    isCampaignSuccess: state.isCampaignSuccess,
    isCampaignLoading: state.isCampaignLoading,
    error: state.error,
  }));

  const { campaigns, isCampaignLoading } = useSelector(campaignProperties);
  // End :: Get List Table

  // Begin:: Call api - filter - for get list
  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date
      ? moment(query?.start_date || "", "Y-MM-DD").toDate()
      : "",
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : "",
  ]);
  const [startDate, endDate] = dateSearch;

  const handleShowPreview = (item: any) => {
    const newData = {
      caption: item?.news_title,
      template_html: item?.news_content,
      date: item?.reserved_date,
    };
    setDataPreview(newData);
  };

  const showMedias = (media_ids: any) => {
    setIsOpenMediasDetail((_prev) => true);
    const cloneData = [...allMedias];
    const newData = media_ids?.map((id: any) =>
      cloneData?.find((item: any) => item?.id === id?.id)
    );
    setMedias(newData);
  };

  // const updateStatusCampaign = async (id: number, status: any) => {
  //   try {
  //     const res: any = await putCampaignStatus(id, { status: status?.value });
  //     if (res?.code === 200) {
  //       dispatch(onGetCampaigns(query));
  //       toast.success(
  //         `${t("The process has been completed.")}`,
  //         CONFIG_OPTION_TOAST_NORMAL
  //       );
  //     } else {
  //       toast.error(`${res?.message || res}`, CONFIG_OPTION_TOAST_ERROR);
  //     }
  //   } catch (error: any) {
  //     toast.error(
  //       `${error?.message || error || ""}`,
  //       CONFIG_OPTION_TOAST_ERROR
  //     );
  //   }
  // };

  const handleConfirmDelete = (item: any) => {
    setInitialValuesDefault((_prev: any) => item);
    setIsConfirm((_prev) => true);
  };

  const columns = useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;

          return (
            <div
              // onClick={(e) => {
              //   setUserEdit((prev) => value);
              //   setIsOpen((_prev) => true);
              // }}
              className="text-primary cursor-pointer "
            >
              {value?.name}
            </div>
          );
        },
      },

      {
        Header: t("News title"),
        accessor: "news_title",
        filterable: false,
        sortable: false,
      },
      {
        Header: t("Status"),
        accessor: "status",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        thWidth: 170,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const status = () => {
            switch (value?.status) {
              case "pending":
                return "warning";
              case "confirmed":
                return "primary";
              case "published":
                return "success";
              case "rejected":
                return "danger";
              default:
                return value?.status;
            }
          };
          return (
            <div style={{ minWidth: "60px" }} className="text-center">
              <span
                className={`badge bg-${COLORS_NEWS_STATUS.find(
                  (value) => value === status()
                )}`}
              >{`${value?.status}`}</span>
            </div>
          );
        },
      },

      {
        Header: t("Transmission type"),
        accessor: "transmission_type",
        filterable: false,
        sortable: false,
      },
      {
        Header: t("Reserved date"),
        accessor: "reserved_date",
        filterable: false,
        sortable: true,
        thWidth: 140,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Package"),
        accessor: "package",
        filterable: false,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;

          return (
            <div onClick={() => showMedias(value?.medias)}>
              <div className="text-primary cursor-pointer">
                {value?.package?.name}
                {value?.transmission_type === "package" && (
                  <span>
                    {" - "}
                    {formatNumber(value?.package?.point).toString()}{" "}
                    {t("point")}
                  </span>
                )}
              </div>
              <div className="text-primary cursor-pointer">
                {value?.medias?.length + " medias"}
              </div>
            </div>
          );
        },
      },

      // {
      //   Header: t("Email"),
      //   accessor: "email",
      //   filterable: false,
      //   sortable: false,
      // },
      // {
      //   Header: t("Role"),
      //   accessor: "role",
      //   filterable: false,
      //   sortable: false,
      //   Cell: (cell: any) => {
      //     const value: any = cell?.row?.original;

      //     return <div>{value?.role?.name}</div>;
      //   },
      // },
      {
        Header: t("Published at"),
        accessor: "published_at",
        filterable: false,
        sortable: true,
        thWidth: 140,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: false,
        sortable: true,
        thWidth: 140,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: false,
        sortable: false,
        thWidth: 110,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-start hstack gap-2 mb-0">
              <TooltipCustom
                title={t("Preview Campaign")}
                id={`detail-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`detail-md-${item?.id}`}>
                  <Link
                    className="btn btn-sm btn-soft-secondary edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowPreview(item);
                    }}
                  >
                    <i className="ri-eye-fill align-bottom "></i>
                  </Link>
                </li>
              </TooltipCustom>
              {item?.status !== "published" && (
                <>
                  <TooltipCustom
                    title={t("Button Update Campaign")}
                    id={`update-us-${item?.id}`}
                  >
                    <li
                      className=" list-inline-item me-0"
                      id={`update-us-${item?.id}`}
                    >
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          setUserEdit((prev: any) => item);
                          setIsOpen((_prev) => true);
                        }}
                      >
                        <i className="ri-pencil-fill align-bottom"></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                  <TooltipCustom
                    title={t("Button Delete Campaign")}
                    id={`detail-cp-${item?.id}`}
                  >
                    <li
                      className="list-inline-item"
                      id={`detail-cp-${item?.id}`}
                    >
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          handleConfirmDelete(item);
                        }}
                      >
                        <i className="ri-delete-bin-3-fill align-bottom"></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                </>
              )}
            </ul>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [i18n?.language, allMedias]
  );

  const refreshData = () => {
    dispatch(onGetCampaigns(query));
  };
  const resetData = () => {
    setQuery(
      {
        keyword: undefined,
        start_date: undefined,
        end_date: undefined,
        status: undefined,
        media_id: undefined,
        filter_date_by: undefined,
      },
      "push"
    );
    setKeyword("");
    setDateSearch(["", ""]);
    setStatusSearch(null);
    setMediaSearch(null);
    setDateTypeSearch(DATE_TYPE_OPTIONS[0]);
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };
  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev: any) => null);
    setIsConfirm((_prev) => false);
  };

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => ({ ..._prev, ...sortBy }));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(onGetCampaigns(query));
  }, [dispatch, query]);

  // End:: Call api - filter - for get list

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteCampaign(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetCampaigns(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev: any) => null);
        toast.success(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast.error(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast.error(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };
  const handleSearch = () => {
    setQuery({
      ...query,
      keyword: keyword,
      page: 1,
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      status: statusSearch?.value,
      media_id: mediaSearch?.value,
      filter_date_by: dateTypeSearch?.value,
    });
  };

  const allMediaOptions = useMemo(() => {
    return allMedias?.map((item: any) => {
      return {
        value: item?.id,
        label: item?.name,
      };
    });
  }, [allMedias]);

  useEffect(() => {
    document.title = `${t("Campaign")} - ${t("Campaign")} | PRHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    const getMedias = async () => {
      const res: any = await getAllMedias({ page: 1, limit: "0" });
      if (res?.code === 200) {
        setAllMedias(res?.data);
      }
    };
    getMedias();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Campaign")} pageTitle={t("Campaign")} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  {" "}
                  <div className="d-flex flex-column flex-md-row g-2 align-items-start align-items-md-end mb-2">
                    <Card
                      className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0 d-none d-md-block"
                      style={{ width: "200px" }}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                            <span className="counter-value text-primary">
                              {isCampaignLoading ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                <CountUp
                                  start={0}
                                  end={campaigns?.pagination?.total || 0}
                                  duration={1}
                                />
                              )}
                            </span>
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <div className="d-flex gap-2 flex-column">
                        <Row className="g-2 align-items-center">
                          <Col
                            md={3}
                            lg={2}
                            className="date-picker-wrapper-custom"
                          >
                            <LabelWrapper
                              label={t("Date type")}
                              isShow={!!dateSearch}
                            >
                              <DropdownOption
                                name="media_id"
                                dataList={DATE_TYPE_OPTIONS || []}
                                placeholder={`${t("Date type")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={dateTypeSearch}
                                onChangeSelect={(e: any) =>
                                  setDateTypeSearch(e)
                                }
                                isHasOptionAll={false}
                                optionAll={{
                                  label: t("All Method"),
                                  value: "",
                                }}
                              ></DropdownOption>
                            </LabelWrapper>
                          </Col>
                          <Col
                            md={5}
                            lg={3}
                            className="date-picker-wrapper-custom"
                          >
                            <LabelWrapper
                              label={t("version_date")}
                              isShow={!!dateSearch}
                            >
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>

                          <Col md={8} lg={4}>
                            <LabelWrapper
                              label={t(`Status`)}
                              isShow={statusSearch?.value ? true : false}
                            >
                              <DropdownStatusNews
                                name="status"
                                dataList={STATUS_CAMPAIGN_OPTIONS_LANG || []}
                                placeholder={`${t("Status")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={statusSearch || null}
                                onChangeSelect={(e: any) => setStatusSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{
                                  label: t("All Status"),
                                  value: "",
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>

                        <Row className="g-2 align-items-center">
                          <Col md={8} lg={5}>
                            <LabelWrapper
                              label={t(`Campaign, News title`)}
                              isShow={keyword ? true : false}
                            >
                              <div className="search-box">
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t(
                                    "Search by Campaign, News title"
                                  )}...`}
                                  value={keyword}
                                  onChange={(e) => setKeyword(e.target.value)}
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </LabelWrapper>
                          </Col>
                          <Col md={8} lg={4}>
                            <LabelWrapper
                              label={t(`Media`)}
                              isShow={mediaSearch?.value ? true : false}
                            >
                              <DropdownOption
                                name="media_id"
                                dataList={allMediaOptions || []}
                                placeholder={`${t("Media")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={mediaSearch || null}
                                onChangeSelect={(e: any) => setMediaSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t("All Media"), value: "" }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col lg={3} md={12} className="hstack gap-1">
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={isCampaignLoading}
                              onClick={handleSearch}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-3">
                  <TableContainer
                    className="custom-header-css"
                    divClass="table-responsive table-card"
                    tableClass="align-middle"
                    theadClass="table-light"
                    columns={columns}
                    data={campaigns?.list?.length ? campaigns?.list : []}
                    customPageSize={query.limit}
                    customPageCount={Math.ceil(
                      Number(campaigns?.pagination?.total) /
                      Number(campaigns?.pagination?.limit)
                    )}
                    customPageIndex={query.page - 1}
                    totalRecords={campaigns?.pagination?.total}
                    handleChangeSorting={handleChangeSorting}
                    handleChangePage={handleChangePage}
                    isLoading={isCampaignLoading}
                    manualSorting={true}
                    sorting={{
                      sort_by: query.sort_by,
                      order_by: query.order_by,
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={isOpen}
          centered={true}
          size="xl"
          scrollable={true}
          toggle={() => {
            setIsOpen(false);
            setUserEdit(null);
          }}
          keyboard={true}
        >
          <ModalHeader
            toggle={() => {
              setIsOpen(false);
              setUserEdit(null);
            }}
          >
            {t("Update Campaign")}
          </ModalHeader>
          <ModalBody className="">
            <CampaignFormMulti
              triggerRefresh={refreshData}
              handleCloseModal={() => {
                setIsOpen(false);
                setUserEdit(null);
              }}
              initialValues={userEdit}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isOpenMediasDetail}
          centered={true}
          size="md"
          scrollable={true}
          toggle={() => {
            setIsOpenMediasDetail(false);
          }}
          keyboard={true}
        >
          <ModalHeader
            toggle={() => {
              setIsOpenMediasDetail(false);
            }}
          >
            {t("Medias Info")}
          </ModalHeader>
          <ModalBody className="mt-0 py-0">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table-borderless mb-0 table">
                    <tbody>
                      {medias &&
                        medias?.map((item: any) => (
                          <tr>
                            <th className="ps-0" scope="row">
                              {item?.name}:
                            </th>
                            <td className="text-muted">
                              <a
                                href={item?.website}
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(
                                    "https:\\" + item?.website,
                                    "_blank"
                                  );
                                }}
                              >
                                {item?.website}
                              </a>
                            </td>
                          </tr>
                        ))}
                      {medias?.length <= 0 && <>No data</>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t("Delete Campaign")}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <Modal
          isOpen={dataPreview}
          centered={true}
          size="xl"
          scrollable={true}
          toggle={() => setDataPreview(null)}
          keyboard={true}
        >
          <ModalHeader toggle={() => setDataPreview(null)}>
            {t("Preview")}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <div className="position-relative">
                        <div className="p-3 pt-4 card-body">
                          <div className="text-center">
                            <h3 className="fw-semibold">
                              {dataPreview?.caption || ""}
                            </h3>
                            <p className="mb-0 text-muted">
                              {t("Reserved Date")}: {dataPreview?.date || ""}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={12}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: dataPreview?.template_html || "",
                        }}
                      ></div>
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          className="btn btn-soft-secondary fs-14"
                          type="button"
                          color="light"
                          onClick={() => setDataPreview(null)}
                        >
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t("Button Close")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default CampaignList;
