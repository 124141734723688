import uploadFileApi from "api/uploadFileApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import { useFormik } from "formik";
import { HELP_TYPE_OPTIONS } from "helpers/constans";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Col, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { postInquiry } from "store/inquiry/thunk";
import { getAllCampaigns } from "store/thunks";
import * as Yup from "yup";

const ModalFormCreate = ({ onCloseClick, show, refreshData }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const validationSchema = Yup.object({
    title: Yup.string().required(`${t("Title is required")}`),
    content: Yup.string().required(`${t("Content is required")}`),
  });
  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data: any = {
        title: values?.title ?? "",
        content: values?.content ?? "",
        attachment: values?.attachment ?? null,
        campaign_id: Number(values?.campaign_id?.value) ?? null,
      };
      if (values?.attachment?.name) {
        const res: any = await uploadFileApi.uploadImage({
          file: data?.attachment,
        });
        if (res?.code === 200) {
          const params: any = {
            title: values?.title ?? "",
            content: values?.content ?? "",
            campaign_id: Number(values?.campaign_id?.value) ?? null,
            attachments: [
              {
                path: res?.data[0]?.path ?? null,
                type:
                  res?.data[0]?.mimetype === "image/png" ? "image" : "image",
              },
            ],
          };
          if (!params?.campaign_id) {
            delete params?.campaign_id;
          }
          handleSubmitData(params);
        }
      }
      if (!values?.attachment?.name) {
        delete data?.attachment;
        handleSubmitData(data);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast.error(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleSubmitData = async (params: any) => {
    const response: any = await postInquiry(params);
    if (response.code === 200) {
      refreshData();
      setIsLoading((_prev) => false);
      formik?.resetForm();
      formik?.setErrors({});
      handleOnClose();
      toast.success(
        `${t("The process has been completed.")}`,
        CONFIG_OPTION_TOAST_NORMAL
      );
      // handleResetForm();
    } else {
      setIsLoading((_prev) => false);
      toast.error(`${response}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  const handleOnClose = () => {
    formik.setValues({
      title: "",
      campaign_id: null,
      content: "",
      attachment: {},
    });
    formik?.setErrors({});
    onCloseClick();
  };

  const getCampaigns = async () => {
    try {
      const res: any = await getAllCampaigns({ page: 1, limit: "0" });
      if (res?.code === 200) {
        setCampaigns(res?.data || []);
      }
    } catch (error: any) {
      toast.error(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
      campaign_id: null,
      attachment: {},
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const campaignsOptions = useMemo(() => {
    return campaigns?.map((item: any) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    });
  }, [campaigns]);
  useEffect(() => {
    getCampaigns();
  }, []);

  useEffect(() => {
    formik.setValues({
      title: "",
      content: "",
      attachment: {},
      type: HELP_TYPE_OPTIONS[0],
    } as any);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsOpen(show);
    return () => setIsOpen(false);
  }, [show]);
  return (
    <Modal isOpen={isOpen} centered={true} size="lg">
      <ModalHeader toggle={isLoading ? () => {} : handleOnClose}>
        {t("Create Inquiry")}
      </ModalHeader>
      <ModalBody className="">
        <div className="row g-4">
          <Col className="order-sm-1 order-md-2">
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-3">
                <Col xxl={12}>
                  <label className="form-label">
                    {t("Title")}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    className="form-control"
                    id="title"
                    name="title"
                    value={formik?.values?.title}
                    placeholder={`${t("Title")}...`}
                    onChange={(event: any) =>
                      formik.setFieldValue("title", event?.target?.value || "")
                    }
                  />
                  {formik?.touched?.title && formik?.errors?.title ? (
                    <div className="text-danger mt-2">
                      {formik?.errors?.title as string}
                    </div>
                  ) : null}
                </Col>

                <Col xxl={12}>
                  <label className="form-label">{t("Campaign")}</label>
                  <DropdownOption
                    name="campaign_id"
                    dataList={campaignsOptions || []}
                    placeholder={`${t("Campaign")}...`}
                    className="search-filter-category-type"
                    classNamePrefix="name-prefix"
                    initialValue={formik?.values?.campaign_id}
                    onChangeSelect={(e: any) =>
                      formik?.setFieldValue("campaign_id", e)
                    }
                    isHasOptionAll={false}
                    optionAll={{ label: t("All Method"), value: "" }}
                  />
                  {formik?.touched?.title && formik?.errors?.title ? (
                    <div className="text-danger mt-2">
                      {formik?.errors?.title as string}
                    </div>
                  ) : null}
                </Col>

                <Col xxl={12}>
                  <label className="form-label">
                    {t("Content")}
                    <span className="text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="content"
                    name="content"
                    rows={5}
                    value={formik?.values?.content}
                    placeholder={`${t("Content")}...`}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "content",
                        event?.target?.value || ""
                      )
                    }
                  />

                  {formik.touched.content && formik.errors.content ? (
                    <div className="text-danger mt-2">
                      {formik.errors.content}
                    </div>
                  ) : null}
                </Col>
                <Col xxl={12}>
                  <label className="form-label">{t("Attachment")}</label>
                  <input
                    className="form-control"
                    id="attachment"
                    name="attachment"
                    type="file"
                    placeholder={`${t("Attachment")}...`}
                    onChange={(event: any) => {
                      formik.setFieldValue(
                        "attachment",
                        event?.target?.files[0] || ""
                      );
                    }}
                    accept=".img,.png,.jpg,.jpeg,.gif,.svg"
                  />
                  {formik.touched.attachment && formik.errors.attachment ? (
                    <div className="text-danger mt-2">
                      {formik.errors.attachment as any}
                    </div>
                  ) : null}
                </Col>

                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      className="btn btn-light fs-14"
                      type="button"
                      color="light"
                      onClick={handleOnClose}
                      disabled={isLoading}
                    >
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t("Button Close")}
                    </button>
                    <button
                      className="btn btn-primary fs-14"
                      color="success"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner size="sm" className="me-2"></Spinner>
                      ) : (
                        <i className="ri-add-fill align-bottom me-1"></i>
                      )}
                      {t("Create Inquiry")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Col>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalFormCreate;
