import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
}
interface DropdownOptionProps {
  name?: string;
  initialValue?: Option | undefined | null;
  dataList?: Option[];
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  classNamePrefix?: string;
  isHasOptionAll?: boolean;
  optionAll?: undefined | Option;
  labelTop?: string;
}

const DropdownOption = ({
  name = "",
  initialValue,
  onChangeSelect,
  dataList = [],
  placeholder,
  disabled = false,
  className = "",
  classNamePrefix = "",
  isHasOptionAll = false,
  optionAll,
  labelTop = "",
}: DropdownOptionProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const resultOptions =
      dataList?.map((item) => ({
        label: item?.label,
        value: String(item?.value || ""),
      })) || ([] as Option[]);
    const result: any = isHasOptionAll
      ? [optionAll].concat(resultOptions)
      : resultOptions;
    setOptions((_prev: any) => result);

    if (initialValue && initialValue?.value) {
      const val = result?.filter(
        (e: any) => e.value === initialValue?.value
      )[0];
      setOnChangeOption(val);
    } else {
      setOnChangeOption(isHasOptionAll ? result[0] : null);
    }

    // eslint-disable-next-line
  }, [JSON.stringify(dataList), JSON.stringify(initialValue)]);

  return (
    <div className="position-relative">
      <Select
        options={options}
        value={initialValue}
        name={name}
        isDisabled={disabled}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{placeholder || ""}</div>}
        loadingMessage={() => <div>{t("Loading")}...</div>}
        noOptionsMessage={() => <div>{t("No Options")}</div>}
        className={className}
        classNamePrefix={classNamePrefix}
        isClearable={false}
        formatOptionLabel={(option: any, index: number) => (
          <div className="d-flex align-items-center">
            {option?.value === "bank_transfer" && (
              <i className="ri-bank-fill me-1"></i>
            )}
            {option?.value === "card" && (
              <i className="ri-bank-card-fill me-1"></i>
            )}
            {t(option?.label)}
          </div>
        )}
      />
      {labelTop && initialValue?.value && (
        <label
          style={{
            position: "absolute",
            left: "5px",
            top: "0px",
            marginBottom: "0px",
            transform: "translateY(-50%)",
            padding: `0px 5px`,
            color: "#c2c2c2",
            fontSize: "12px",
            fontWeight: "400",
            borderRadius: "3px",
            backgroundColor: disabled
              ? "hsl(0, 0%, 95%)"
              : "var(--vz-secondary-bg)",
          }}
        >
          {labelTop}
        </label>
      )}
    </div>
  );
};

export default DropdownOption;
