import { useState } from "react";
import { getLoggedInUserInfo } from "../../helpers/api_helper";
import { useSelector } from "react-redux";

const useProfile = () => {
  const [userProfile] = useState<any>(getLoggedInUserInfo());
  const [loading] = useState<boolean>(userProfile ? false : true);

  return { userProfile, loading };
};

const useRole = () => {
  const { user } = useSelector((state: any) => state?.Login);
  const userPermissions = user?.user?.permissions || [];
  return { userPermissions };
};

const useBalance = () => {
  const { profile: userBalance } = useSelector((state: any) => state?.Profile);
  return { userBalance };
};

export { useProfile, useRole, useBalance };
