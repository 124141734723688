import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { TRANSMISSION_TYPE, formatNumber } from "helpers/constans";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";

import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createSelector } from "reselect";
import { postCampaign, putCampaign } from "store/campaign/thunk";
import {
  getMedias as onGetMedias,
  getPackages as onGetPackages,
} from "store/thunks";

import EditorCustom from "components/Common/EditorCustom";
import ModalConfirm from "components/Common/ModalConfirm";
import { useBalance } from "components/Hooks/UserHooks";
import moment from "moment";
import Select from "react-select";
import * as Yup from "yup";
export interface Tag {
  id: string;
  text: string;
}

export interface Props {
  typeCampaignForm?: string;
  isModal?: boolean;
  id?: string;
  initialValues?: any | null;
  triggerRefresh?: () => void;
  handleCloseModal?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const CampaignFormMulti = ({
  typeCampaignForm = "",
  isModal = false,
  id = "",
  initialValues,
  triggerRefresh,
  handleCloseModal,
}: Props) => {
  const { t, i18n } = useTranslation();

  // eslint-disable-next-line
  const [balance, setBalance] = useState<any>(0);
  const refContentNews = useRef<any>(null);
  const dispatch: any = useDispatch();
  const { userBalance } = useBalance();
  const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);

  const [isOpenConfirmCreate, setIsConfirmCreate] = useState<boolean>(false);

  const handleShowConfirmCreateCampaign = () => {
    handleSubmit(formik.values);
  };

  const handleCreateCampaign = () => {
    setIsConfirmCreate((_prev) => false);
    handleSubmit(formik.values);
  };

  const onCloseConfirmCreateClick = () => {
    setIsConfirmCreate((_prev) => false);
  };

  const handleSubmit = async (values: any) => {
    try {
      const cloneData = { ...formik?.values };
      const params: any = {
        name: cloneData?.name,
        news_title: cloneData?.news_title,
        news_content: refContentNews?.current?.getContent() ?? "",
        package_id: cloneData?.package_id as any,
        reserved_date: cloneData?.reserved_date || "2024-03-20",
        additional_comments: cloneData?.additional_comments,
        transmission_type: cloneData?.transmission_type,
        media_ids: cloneData?.media_ids?.map((item: any) => item?.value),
      };
      setIsLoadingCreate(true);

      const res: any = initialValues?.id
        ? await putCampaign(initialValues?.id, params)
        : await postCampaign(params);
      if (res?.code === 200) {
        if (initialValues?.id) {
          triggerRefresh && triggerRefresh();
          handleCloseModal && handleCloseModal();
        }
        formik.resetForm();
        toast.success(
          `${t(
            initialValues?.id
              ? "Update campaign success"
              : "Create campaign success"
          )}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        formik?.resetForm();
        refContentNews?.current?.setContent("");
      } else {
        toast.error(
          res || `${t("Create campaign failed")}`,
          CONFIG_OPTION_TOAST_ERROR
        );
      }
      setIsLoadingCreate(false);
    } catch (error: any) {
      setIsLoadingCreate(false);
      toast.error(
        `${error?.message || error || ""}`,
        CONFIG_OPTION_TOAST_ERROR
      );
    }
  };

  const selectLayoutPackageState = (state: any) => state.Package;
  const packageProperties = createSelector(
    selectLayoutPackageState,
    (state) => ({
      allPackage: state.allPackage,
    })
  );
  const { allPackage } = useSelector(packageProperties);

  const allPackageOption = useMemo(() => {
    return allPackage?.map((item: any) => ({
      value: item?.id,
      label: item?.name,
      point: item?.point,
    }));
  }, [allPackage]);

  useEffect(() => {
    if (!allPackage || allPackage?.length <= 0) {
      dispatch(onGetPackages({}));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const selectLayoutState = (state: any) => state.Media;
  const mediaProperties = createSelector(selectLayoutState, (state) => ({
    allMedia: state.allMedia,
    medias: state.medias,
  }));
  const { allMedia } = useSelector(mediaProperties);
  const allMediaOption = useMemo(() => {
    return allMedia?.map((item: any) => ({
      value: item?.id,
      label: item?.name,
      point: item?.point,
    }));
  }, [allMedia]);

  useEffect(() => {
    if (!allMedia || allMedia?.length <= 0) {
      dispatch(onGetMedias({}));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const initName = useMemo(() => {
    if (initialValues) {
      return initialValues?.name;
    }
    return "";
  }, [initialValues]);

  const initNewsTitle = useMemo(() => {
    if (initialValues) {
      return initialValues?.news_title;
    }
    return "";
  }, [initialValues]);

  const initNewsContent = useMemo(() => {
    if (initialValues) {
      return initialValues?.news_content;
    }
    return "";
  }, [initialValues]);

  const initComment = useMemo(() => {
    if (initialValues) {
      return initialValues?.additional_comments;
    }
    return "";
  }, [initialValues]);

  const initReservedDate = useMemo(() => {
    if (initialValues) {
      return initialValues?.reserved_date;
    }
    return "";
  }, [initialValues]);

  const initTransmissionType = useMemo(() => {
    if (initialValues) {
      return initialValues?.transmission_type;
    }
    return TRANSMISSION_TYPE[0].value;
  }, [initialValues]);

  const initPackage = useMemo(() => {
    if (initialValues) {
      return initialValues?.package_id;
    }
    return null;
  }, [initialValues]);

  const initMedia = useMemo(() => {
    if (initialValues && allMediaOption) {
      const medias = initialValues?.media_ids?.map((item: any) => {
        const mediasData = allMediaOption?.find(
          (media: any) => media?.value === item
        );
        return mediasData;
      });
      return medias;
    }
    return [];
  }, [initialValues, allMediaOption]);

  const validationSchema = Yup.object({
    name: Yup.string().required(`${t("Name is required")}`),

    news_title: Yup.string().required(`${t("News title is required")}`),
    reserved_date: Yup.string().required(
      `${t("Reserved date can not less than now")}`
    ),
    additional_comments: Yup.string(),
    transmission_type: Yup.string().required(
      `${t("Transmission type is required")}`
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: initName,
      news_title: initNewsTitle,
      news_content: initNewsContent,
      package_id: initPackage,
      reserved_date: initReservedDate,
      additional_comments: initComment,
      transmission_type: initTransmissionType,
      media_ids: initMedia,
    },
    validationSchema,
    onSubmit: handleShowConfirmCreateCampaign,
  });

  const handleOnChangeTransmissionType = (e: any) => {
    formik.setFieldValue("transmission_type", e?.value);
    formik.setFieldValue("media_ids", []);
    formik.setFieldValue("package_id", null);
  };

  const handleUpdatePackage = (e: any) => {
    formik.setFieldValue("package_id", e?.value);
  };

  const estimateFee = () => {
    if (formik?.values?.transmission_type === "package") {
      const packageDetail = allPackageOption?.find(
        (item: any) => item?.value === formik?.values?.package_id
      );
      return packageDetail?.point || 0;
    }
    if (formik?.values?.transmission_type === "media") {
      return formik?.values?.media_ids
        ?.map((item: any) => item?.point || 0)
        .reduce((a: any, b: any) => a + b, 0);
    }
  };
  const advertiserBalance = useMemo(() => {
    return userBalance?.point || 0;
  }, [userBalance]);
  const validatePayment = useMemo(() => {
    if (estimateFee() > advertiserBalance) {
      return (
        <button
          className="btn btn-warning fs-12 mt-2"
          color="light"
          type="button"
        >
          {t("Topup now")}
        </button>
      );
    } else {
      return (
        <i
          className="ri-check-double-line text-success ms-1"
          style={{ fontSize: "1rem" }}
        ></i>
      );
    }
    // eslint-disable-next-line
  }, [estimateFee(), advertiserBalance]);

  useEffect(() => {
    document.title = `${t("Campaign Create")} - ${t("Campaign")} | PRHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    if (initialValues) {
      formik?.setFieldValue("reserved_date", initialValues?.reserved_date);
      formik?.setFieldValue(
        "transmission_type",
        initialValues?.transmission_type
      );
      formik?.setFieldValue("package_id", initialValues?.package_id);
      formik?.setFieldValue("media_ids", initMedia);
      formik?.setFieldValue("name", initialValues?.name);
      formik?.setFieldValue("news_title", initialValues?.news_title);
      formik?.setFieldValue("news_content", initialValues?.news_content);
      formik?.setFieldValue(
        "additional_comments",
        initialValues?.additional_comments
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, allMediaOption]);

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col lg={12}>
            <Card id="leadsList">
              <CardBody className="pt-2">
                <Row className={`mt-1 gap-3 gap-sm-0`}>
                  <Col className="px-1" sm={4}>
                    <div
                      className="d-flex flex-column gap-3 h-100"
                      style={{
                        backgroundColor: "var(--vz-topbar-search-bg)",
                        padding: "10px 10px 20px",
                        borderRadius: "5px",
                      }}
                    >
                      <Col
                        xxl={12}
                        className="d-flex justify-content-between align-items-end"
                      >
                        <div style={{ width: "100%" }}>
                          <label className="form-label">
                            {t("Campaign Name")}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            id="name"
                            name="name"
                            value={formik?.values?.name}
                            placeholder={`${t("Campaign Name")}...`}
                            onChange={(event: any) =>
                              formik.setFieldValue(
                                "name",
                                event?.target?.value || ""
                              )
                            }
                          />
                          {formik?.touched?.name && formik?.errors?.name ? (
                            <div className="text-danger mt-2">
                              {" "}
                              {formik.errors.name as string}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={12}>
                        <div style={{ width: "100%" }}>
                          <label className="form-label">
                            {t("News Title")}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            id="news_title"
                            name="news_title"
                            value={formik?.values?.news_title}
                            placeholder={`${t("News Title")}...`}
                            onChange={(event: any) =>
                              formik.setFieldValue(
                                "news_title",
                                event?.target?.value || ""
                              )
                            }
                          />
                          {formik?.touched?.news_title &&
                            formik?.errors?.news_title ? (
                            <div className="text-danger mt-2">
                              {" "}
                              {formik.errors.news_title as string}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={12}>
                        <div style={{ width: "100%" }}>
                          <label className="form-label">
                            {t("Additional Comments")}
                          </label>
                          <textarea
                            className="form-control"
                            id="additional_comments"
                            name="additional_comments"
                            value={formik?.values?.additional_comments}
                            placeholder={`${t("Additional Comments")}...`}
                            onChange={(event: any) =>
                              formik.setFieldValue(
                                "additional_comments",
                                event?.target?.value || ""
                              )
                            }
                            rows={4}
                          />
                          {formik?.touched?.additional_comments &&
                            formik?.errors?.additional_comments ? (
                            <div className="text-danger mt-2">
                              {" "}
                              {formik.errors.additional_comments as string}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <Col xxl={12}>
                        <div>
                          <label className="form-label">
                            {t("Transmission Type")}
                            <span className="text-danger"> *</span>
                          </label>
                          <Select
                            options={TRANSMISSION_TYPE || []}
                            name="transmission_type"
                            value={TRANSMISSION_TYPE?.find(
                              (item: any) =>
                                item?.value ===
                                formik?.values?.transmission_type
                            )}
                            onChange={(e: any) => {
                              handleOnChangeTransmissionType(e);
                            }}
                            placeholder={
                              <div>{`${t("Transmission Type")}`}</div>
                            }
                            loadingMessage={() => <div>{t("Loading")}...</div>}
                            noOptionsMessage={() => (
                              <div>{t("No Options")}</div>
                            )}
                            className="search-template-chart w-100"
                            classNamePrefix="name-prefix"
                          />
                          {formik?.touched?.transmission_type &&
                            formik?.errors?.transmission_type ? (
                            <div className="text-danger mt-2">
                              {" "}
                              {formik.errors.transmission_type as string}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      {formik?.values?.transmission_type === "package" && (
                        <Col xxl={12}>
                          <div>
                            <label className="form-label">
                              {t("Package")}
                              <span className="text-danger"> *</span>
                            </label>
                            <Select
                              options={allPackageOption || []}
                              name="package"
                              value={allPackageOption?.find(
                                (item: any) =>
                                  item?.value === formik?.values?.package_id
                              )}
                              onChange={(e: any) => {
                                handleUpdatePackage(e);
                              }}
                              placeholder={
                                <div>{`${t("Transmission Type")}`}</div>
                              }
                              loadingMessage={() => (
                                <div>{t("Loading")}...</div>
                              )}
                              noOptionsMessage={() => (
                                <div>{t("No Options")}</div>
                              )}
                              className="search-template-chart w-100"
                              classNamePrefix="name-prefix"
                              formatOptionLabel={(option: any) => (
                                <div className="d-flex align-items-start">
                                  <div>
                                    {option?.label}
                                    {" - "}
                                    <span className="text-primary">
                                      {formatNumber(option?.point)}
                                    </span>
                                  </div>
                                </div>
                              )}
                            />
                            {/* {(formik.touched?.campaign_medias?.[i] as any) &&
                              (formik.errors?.campaign_medias?.[i] as any)
                                ?.media ? (
                                <div className="text-danger mt-2">
                                  {
                                    (formik.errors?.package?.[i] as any)
                                      ?.media
                                  }
                                </div>
                              ) : null} */}
                          </div>
                        </Col>
                      )}
                      {formik?.values?.transmission_type === "media" && (
                        <Col xxl={12}>
                          <div>
                            <label className="form-label">
                              {t("Media")}
                              <span className="text-danger"> *</span>
                            </label>
                            <Select
                              isMulti
                              options={allMediaOption || []}
                              name="media_ids"
                              value={formik?.values?.media_ids}
                              onChange={(e: any) => {
                                formik?.setFieldValue("media_ids", e);
                              }}
                              placeholder={<div>{`${t("Media")}`}</div>}
                              loadingMessage={() => (
                                <div>{t("Loading")}...</div>
                              )}
                              noOptionsMessage={() => (
                                <div>{t("No Options")}</div>
                              )}
                              className="search-template-chart w-100"
                              classNamePrefix="name-prefix"
                              formatOptionLabel={(option: any) => (
                                <div className="d-flex align-items-start">
                                  <div>
                                    {option?.label}
                                    {" - "}
                                    <span className="text-success fw-semibold">
                                      {formatNumber(option?.point)}
                                    </span>
                                  </div>
                                </div>
                              )}
                            />
                            {/* {(formik.touched?.campaign_medias?.[i] as any) &&
                              (formik.errors?.campaign_medias?.[i] as any)
                                ?.media ? (
                                <div className="text-danger mt-2">
                                  {
                                    (formik.errors?.package?.[i] as any)
                                      ?.media
                                  }
                                </div>
                              ) : null} */}
                          </div>
                        </Col>
                      )}

                      <Col xxl={12}>
                        <div className="d-flex flex-column">
                          <label className="form-label">
                            {t("Reserve Date")}
                            <span className="text-danger"> *</span>
                          </label>
                          <DatePicker
                            className="form-control search"
                            selected={
                              formik?.values?.reserved_date
                                ? moment(
                                  formik?.values?.reserved_date,
                                  "Y-MM-DD"
                                )?.toDate()
                                : moment(new Date(), "Y-MM-DD")
                                  .add(1, "days")
                                  ?.toDate()
                            }
                            onChange={(date) => {
                              const dateString = moment(date).format("Y-MM-DD");
                              formik?.setFieldValue(
                                "reserved_date",
                                dateString
                              );
                            }}
                            dateFormat="yyy-MM-dd"
                            calendarClassName={"w-100"}
                            minDate={moment(new Date(), "Y-MM-DD")
                              .add(1, "days")
                              ?.toDate()}
                          />
                          {formik?.touched?.reserved_date &&
                            formik?.errors?.reserved_date ? (
                            <div className="text-danger mt-2">
                              {" "}
                              {formik.errors.reserved_date as string}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      {estimateFee() > 0 && (
                        <Col xxl={12}>
                          <Card className="p-3 bg-light">
                            <div className="d-flex justify-content-between">
                              <label className="form-label fs-6">
                                {t("Balance")}:
                              </label>
                              <span className="fs-5 ms-2 fw-semibold text-primary">
                                {formatNumber(advertiserBalance)} point
                              </span>
                              {/* {(formik.touched?.campaign_medias?.[i] as any) &&
          (formik.errors?.campaign_medias?.[i] as any)
            ?.media ? (
            <div className="text-danger mt-2">
              {
                (formik.errors?.package?.[i] as any)
                  ?.media
              }
            </div>
          ) : null} */}
                            </div>
                            {estimateFee() > 0 && (
                              <div className="d-flex justify-content-between">
                                <label className="form-label fs-6">
                                  {t("Estimate Fee")}:
                                </label>
                                <span className="fs-5 ms-2 fw-semibold text-primary">
                                  {formatNumber(estimateFee())} point
                                </span>
                                {/* {(formik.touched?.campaign_medias?.[i] as any) &&
          (formik.errors?.campaign_medias?.[i] as any)
            ?.media ? (
            <div className="text-danger mt-2">
              {
                (formik.errors?.package?.[i] as any)
                  ?.media
              }
            </div>
          ) : null} */}
                              </div>
                            )}
                            <div className="text-end">
                              {estimateFee() > 0 &&
                                advertiserBalance &&
                                validatePayment}
                            </div>
                          </Card>
                        </Col>
                      )}

                      {/* Begin::List Section */}
                    </div>
                  </Col>
                  <Col className="px-1" sm={8}>
                    <div
                      className="g-4 g-sm-0 h-100"
                      style={{
                        backgroundColor: "var(--vz-topbar-search-bg)",
                        padding: "10px 10px 20px",
                        borderRadius: "5px",
                      }}
                    >
                      <Col sm={12}>
                        <div style={{ width: "100%" }}>
                          <label className="form-label">
                            {t("News Content")}
                            <span className="text-danger"> *</span>
                          </label>
                          <EditorCustom
                            height="calc(100dvh - 400px)"
                            initialValue={formik?.values?.news_content || ""}
                            ref={refContentNews}
                            toolbar="blocks | aligncenter alignjustify alignleft alignnone alignright| forecolor  backcolor | bold italic strikethrough underline | table | link | image | media | blockquote | charmap | print"
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <div className="col-lg-12 mt-4">
                  <div className="hstack justify-content-sm-center justify-content-md-end mt-3 mb-4">
                    <>
                      <button
                        className="btn btn-success fs-14"
                        color="light"
                        type="submit"
                        disabled={isLoadingCreate}
                      >
                        {isLoadingCreate ? (
                          <Spinner size="sm" className="me-2"></Spinner>
                        ) : (
                          <i className="ri-login-circle-line align-bottom me-1"></i>
                        )}
                        {t(
                          initialValues?.id
                            ? "Update Campaign"
                            : "Create Campaign"
                        )}
                      </button>
                    </>
                  </div>
                </div>
                {!isModal && (
                  <Col sm={0} md={0} xl={1} lg={1} className="m-0"></Col>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </form>

      <ModalConfirm
        textButtonConfirm={t("Button Post")}
        classButtonConfirm="btn-primary"
        classIconButtonConfirm="ri-send-plane-line"
        header={t("Campaign Create")}
        title={t("Are you sure you want to post this?")}
        content={t(
          "After confirming the post action, the data will be created. Do you want to proceed with the confirming action."
        )}
        isShowIcon={false}
        isOpen={isOpenConfirmCreate}
        onClose={onCloseConfirmCreateClick}
        onConfirm={handleCreateCampaign}
      />
    </React.Fragment>
  );
};

export default CampaignFormMulti;
