import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useProfile } from "../components/Hooks/UserHooks";

import { useDispatch } from "react-redux";
import { getProfile as onGetProfile } from "store/thunks";
const AuthProtected = (props: any) => {
  const { userProfile, loading } = useProfile();
  const dispatch: any = useDispatch();
  /*
    Navigate is un-auth access protected routes via url
    */

  if (!userProfile && loading) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    dispatch(onGetProfile({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{props.children}</>;
};

export default AuthProtected;
