import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
var api = new APIClient();

const path = "/charges";
const chargeApi = {
  charges(
    params: any
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },

  createCharge(dataForm: any): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm);
  },

  createPayLetter(
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/pay-letter`;
    return api.post(url, dataForm);
  },
  receiptInfo(id: number, 
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}/receipt-info/${id}`;
    return api.get(url, params);
  },
};

export default chargeApi;
