import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getGenders } from "./thunk";

export interface IState {
  genders: (ResponseData<any[]> & PaginationResponse) | null;
  isGenderLoading: boolean;
  isGenderSuccess: boolean;

  allGender: ResponseData<any[]> | null;

  error: any;
}

export const initialState: IState = {
  genders: null,
  isGenderLoading: false,
  isGenderSuccess: false,
  allGender: null,
  error: {},
};

const packageSlice = createSlice({
  name: "Gender",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Packages
    builder.addCase(getGenders.pending, (state: IState, action: any) => {
      state.isGenderLoading = true;
    });
    builder.addCase(getGenders.fulfilled, (state: IState, action: any) => {
      state.genders = action.payload.data;
      state.isGenderSuccess = true;
      state.isGenderLoading = false;
      state.allGender = action.payload.data?.list;
    });
    builder.addCase(getGenders.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isGenderSuccess = false;
      state.isGenderLoading = false;
    });
  },
});

export default packageSlice.reducer;
