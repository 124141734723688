import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Dashboard CRM
import DashboardReducer from "./dashboard/reducer";

//Campaign
import CampaignReducer from "./campaign/reducer";

// User
import UserReducer from "./user/reducer";

// Role
import RoleReducer from "./role/reducer";

// Global
import GlobalReducer from "./global/reducer";

// Configuration
import ConfigurationReducer from "./configuration/reducer";

// Media
import MediaReducer from "./media/reducer";

// Package
import PackageReducer from "./package/reducer";

// Advertiser
import AdvertiserReducer from "./advertiser/reducer";

//Point Charge
import ChargeReducer from "./point-charge/reducer";

//Point Payment
import PaymentReducer from "./point-payment/reducer";

//Analysis Gender Age
import GenderReducer from "./analysis-gender-age/reducer";

//Analysis Keyword
import KeywordReducer from "./analysis-keyword/reducer";

//Report Report
import ReportReducer from "./report-report/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Dashboard: DashboardReducer,
  Campaign: CampaignReducer,
  User: UserReducer,
  Role: RoleReducer,
  Global: GlobalReducer,
  Configuration: ConfigurationReducer,
  Media: MediaReducer,
  Package: PackageReducer,
  Advertiser: AdvertiserReducer,
  Charge: ChargeReducer,
  Payment: PaymentReducer,
  Gender: GenderReducer,
  Keyword: KeywordReducer,
  Report: ReportReducer,
});

export default rootReducer;
