//Include Both Helper File with needed methods
import inquiryApi from "api/inquiryApi";
import { formatQueryParams } from "helpers/format";
export const getInquiryList = async (params: any = {}) => {
  try {
    const response = await inquiryApi.inquiries(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const postInquiry = async (data: any = {}) => {
  try {
    const response = await inquiryApi.postInquiry(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteInquiry = async (id: any) => {
  try {
    const response = await inquiryApi.deleteInquiry(id);
    return response;
  } catch (error) {
    return error;
  }
};
