import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";

import { Col, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from "reactstrap";
import moment from "moment";
import { formatNumberWithCommas } from "helpers/format";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";
import TooltipCustom from "./TooltipCustom";
import { toast } from "react-toastify";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import { receiptInfo } from "store/thunks";

interface TableHistoryProps {
  className?: any;
  summary?: any;
  data?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  scrollHorizontalAuto?: boolean;
  description?: string;
  customPageIndex?: any;
  customPageCount?: any;
  totalRecords?: any;
  handleChangePage?: any;
  sorting?: { sort_by: string, order_by: string };
  handleChangeSorting?: (sortBy: { sort_by: string | undefined | null, order_by: string | undefined | null }) => void,
  manualSorting?: boolean,
  isLoading?: boolean,
  isShowPagination?: boolean,
  isShowLoadingBottom?: boolean,
  isShowColumn?: boolean,
  classBackgroundWhenActiveSort?: string,
  isScrollToTop?: boolean,
}

const colors: any = {
  'completed': 'primary',
  'cancel': 'danger'
};

function getObBackground(date: string) {
  // const num = moment(date).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
  // const ob = num === 0 ? { backgroundColor: 'rgba(240, 101, 72, 0.2)' } : (num === 6 ? { backgroundColor: 'rgba(37, 160, 226, 0.2)' } : {});
  return {};
}

const TableHistory = ({
  summary,
  data = [],
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  scrollHorizontalAuto = false,
  description,
  customPageIndex,
  customPageCount,
  handleChangePage,
  totalRecords,
  sorting,
  handleChangeSorting,
  manualSorting = false,
  isLoading = false,
  isShowPagination = true,
  isShowLoadingBottom = false,
  isShowColumn = true,
  classBackgroundWhenActiveSort = '',
  isScrollToTop = true,
}: TableHistoryProps) => {

  const { t } = useTranslation();

  const loadingPayLetterRef = useRef<any>();

  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);
  const [scrollLeft, setScrollLeft] = useState<any>(0);

  const tableRef = useRef<any>(null);
  const warperTableRef = useRef<any>(null);
  const elementFixedRef = useRef<any>(null);
  const elementTable: any = tableRef?.current?.getBoundingClientRect() || {};
  const elementWarperTable: any = warperTableRef?.current?.getBoundingClientRect() || {};

  const [rowsShow, setRowsShow] = useState<string[]>([]);

  const { events } = useDraggable(warperTableRef);

  const [idLoadingReceipt, setIdLoadingReceipt] = useState<number | null>(null);

  const [linkPayLetter, setLinkPayLetter] = useState<string | null>(null);

  const generateSortingIndicator = (column: string = '') => {
    return (
      column ? (
        <span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
          {(column === sorting?.sort_by && sorting?.order_by === 'ASC') && <i className={`ri-arrow-up-line fs-13 text-primary`} />}
          {(column === sorting?.sort_by && sorting?.order_by === 'DESC') && <i className={`ri-arrow-down-line fs-13 text-primary`} />}
        </span>
      ) : (
        ""
      )
    );
  };
  const generateDescription = (description: any) => {
    return (
      description ? (
        <TooltipCustom
          title={description || ''}
          className="d-inline-block vertical-align-middle ms-1"
          style={{ transform: 'translateY(2px)' }}
        >
          <i className="ri-question-line align-bottom text-secondary" ></i>
        </TooltipCustom>
      ) : (<></>)
    );
  };

  const handleScroll = (e: any) => {
    setScrollLeft((prev: any) => e?.target?.scrollLeft)
    if (warperTableRef.current && elementFixedRef.current) {
      // If warperTableRef scrolls, then scroll elementFixedRef as well
      if (e.target === warperTableRef.current) {
        elementFixedRef.current.scrollLeft = e?.target?.scrollLeft;
      }
      // If elementFixedRef scrolls, then scroll warperTableRef as well
      else if (e.target === elementFixedRef.current) {
        warperTableRef.current.scrollLeft = e?.target?.scrollLeft;
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document?.documentElement?.scrollTop || 0;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const MARGIN_BOTTOM_HEIGHT = 170; // 170px
      if ((scrollTop + windowHeight + MARGIN_BOTTOM_HEIGHT) >= documentHeight) {
        setIsScrollBottom((prev) => true);
      } else {
        setIsScrollBottom((prev) => false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleShowMore = (str: string) => {
    if (rowsShow?.includes(str)) {
      setRowsShow((prev) => prev?.filter((item) => item !== str));
    } else {
      setRowsShow((prev) => [...prev, str]);
    }
  }

  const handleShowReceipt = async (idPayment: number) => {
    try {
      setIdLoadingReceipt((_prev) => idPayment);
      const response: any = await receiptInfo(idPayment);
      if (response?.data) {
        setIdLoadingReceipt((_prev) => null);
        setLinkPayLetter((_prev) => response?.data?.receipt_url || null);
      } else {
        setIdLoadingReceipt((_prev) => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIdLoadingReceipt((_prev) => null);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (loadingPayLetterRef.current) {
        loadingPayLetterRef.current.classList.add('d-none');
      }
    }, 2000);
    // Clear timeout to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }, [linkPayLetter]);

  return (
    <Fragment>
      <div className={`table-custom-pagination ${divClass}`} {...events} ref={warperTableRef} onScroll={handleScroll}>
        <Table hover className={`${tableClass} ${Number(scrollLeft || 0) > 0 && 'is-scroll'}`} style={{ minHeight: '70px' }} >
          <thead className={theadClass} ref={tableRef}>
            <tr className={trClass}>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ width: '200px' }}
                colSpan={3}
                onClick={(e) => {
                  if ((!sorting?.sort_by || !sorting?.order_by)) {
                    const sortBy = { sort_by: 'date', order_by: 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = { sort_by: 'date', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'date' ? 'ASC' : 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t('Date')}
                {generateSortingIndicator('date')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '220px' }}
              // onClick={(e) => {
              //   if ((!sorting?.sort_by || !sorting?.order_by)) {
              //     const sortBy = { sort_by: 'point', order_by: 'DESC' };
              //     handleChangeSorting && handleChangeSorting(sortBy);
              //   } else if (sorting?.sort_by && sorting?.order_by) {
              //     const sortBy = { sort_by: 'point', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'point' ? 'ASC' : 'DESC' };
              //     handleChangeSorting && handleChangeSorting(sortBy);
              //   }
              // }}
              >
                {t('Charge Points')}
                {generateSortingIndicator('point')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '200px' }}
                onClick={(e) => {
                  if ((!sorting?.sort_by || !sorting?.order_by)) {
                    const sortBy = { sort_by: 'amount', order_by: 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  } else if (sorting?.sort_by && sorting?.order_by) {
                    const sortBy = { sort_by: 'amount', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'amount' ? 'ASC' : 'DESC' };
                    handleChangeSorting && handleChangeSorting(sortBy);
                  }
                }}
              >
                {t('Amount')}
                {generateSortingIndicator('amount')}
              </th>
              <th
                className={`text-center cursor-pointer ${thClass || ''}`}
                style={{ minWidth: '150px' }}
              // onClick={(e) => {
              //   if ((!sorting?.sort_by || !sorting?.order_by)) {
              //     const sortBy = { sort_by: 'count', order_by: 'DESC' };
              //     handleChangeSorting && handleChangeSorting(sortBy);
              //   } else if (sorting?.sort_by && sorting?.order_by) {
              //     const sortBy = { sort_by: 'count', order_by: sorting?.order_by === 'DESC' && sorting?.sort_by === 'count' ? 'ASC' : 'DESC' };
              //     handleChangeSorting && handleChangeSorting(sortBy);
              //   }
              // }}
              >
                {t('Total of Charge')}
                {generateSortingIndicator('count')}
              </th>
            </tr>
            <tr >
              <th colSpan={7} className="py-0 px-0" style={{ borderBottomColor: 'transparent' }}>
                <div className={`infinite-loading-outer ${isLoading && 'is-show-loading'}`}>
                  <div className="infinite-loading-inner"></div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="text-center" role="cell" colSpan={3}>
                <div className="text-center">{t('Sum')}</div>
              </td>
              <td className="text-center" role="cell">
                <div>{formatNumberWithCommas(summary?.total_point || 0)}</div>
              </td>
              <td className="text-center" role="cell">
                <div>{formatNumberWithCommas(summary?.total_amount || 0)}</div>
              </td>
              <td className="text-center" role="cell">
                <div>{formatNumberWithCommas(summary?.total_count || 0)}</div>
              </td>
            </tr>
            {data.map((item: any, index: number) => (
              <>
                <tr key={item?.date} style={getObBackground(item?.date)}>
                  <td className="text-start" role="cell" rowSpan={rowsShow?.includes(item?.date) ? (item?.detail?.length + 1) : 1} style={{ borderRight: 'var(--vz-card-border-width) solid var(--vz-card-border-color)', ...(rowsShow?.includes(item?.date) ? getObBackground(item?.date) : {}) }}>
                    <div className="d-flex justify-content-between align-item-center">
                      <div>
                        {item?.date} <br />
                        {item?.business_name && (
                          <Link className='text-normal' to="#">
                            {item?.business_name}
                          </Link>
                        )}
                      </div>
                      {item?.detail?.length ? <div className="p-2 cursor-pointer" onClick={() => handleShowMore(item?.date)} style={{ fontWeight: 'bold', fontSize: '22px' }}>
                        {rowsShow?.includes(item?.date) ? <i className="bx bx-minus"></i> : <i className="bx bx-plus"></i>}
                      </div> : <></>}
                    </div>
                  </td>
                  <td className="text-center" role="cell" colSpan={2} style={rowsShow?.includes(item?.date) ? { backgroundColor: '#eaeaea' } : {}}>
                    <div className="d-flex justify-content-center align-item-center">
                      <div className="ps-2">{t('Sum')}</div>
                    </div>
                  </td>
                  <td className="text-center" role="cell" style={rowsShow?.includes(item?.date) ? { backgroundColor: '#eaeaea' } : {}}>
                    <div>{formatNumberWithCommas(item?.point || 0)}</div>
                  </td>
                  <td className="text-center" role="cell" style={rowsShow?.includes(item?.date) ? { backgroundColor: '#eaeaea' } : {}}>
                    <div>{formatNumberWithCommas(item?.amount || 0)}</div>
                  </td>
                  <td className="text-center" role="cell" style={rowsShow?.includes(item?.date) ? { backgroundColor: '#eaeaea' } : {}}>
                    <div>{formatNumberWithCommas(item?.count || 0)}</div>
                  </td>
                </tr>

                {rowsShow?.includes(item?.date) && (<>{item?.detail?.map((charge: any, i: number) => (
                  <tr key={`${item?.date}-${i}`} style={{ backgroundColor: '#f8f8f8' }}>
                    <td className="text-start " role="cell">
                      <div className="fs-12">{String(charge?.created_at).split(' ')[1] || ''}</div>
                    </td>
                    <td className="text-start" role="cell">
                      <div className="fs-12">{t(`SHORT_METHOD_${charge?.method || ''}`)}</div>
                    </td>
                    {/* <td className="text-start" role="cell">
                      <div className={`fs-12 text-${colors?.[charge?.status] || 'secondary'}`}> {t(`${charge?.status || ''}`)} </div>
                    </td> */}
                    <td className="text-center" role="cell">
                      <div>{formatNumberWithCommas(charge?.point || 0)}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <div>{formatNumberWithCommas(charge?.amount || 0)}</div>
                    </td>
                    <td className="text-center" role="cell">
                      <button
                        className="btn btn-sm btn-soft-primary"
                        onClick={() => { idLoadingReceipt === null && handleShowReceipt(charge?.id) }}
                      >
                        {(idLoadingReceipt === charge?.id) ? <Spinner size="sm" style={{ width: '12px', height: '12px' }}></Spinner> : <i className="ri-secure-payment-line align-bottom"></i>}  {t('Show Receipt')}
                      </button>
                    </td>
                  </tr>
                ))}
                </>)}
              </>
            ))}
          </tbody>
        </Table>
      </div>
      <div
        className="overflow-x-auto color-scrollbar-os"
        style={{
          position: 'fixed',
          zIndex: 999,
          left: elementWarperTable?.left,
          bottom: 0,
          width: elementWarperTable?.width,
          right: 0,
          opacity: isScrollBottom ? 0 : 1,
        }}
        ref={elementFixedRef}
        onScroll={handleScroll}
      >
        <div style={{ width: elementTable?.width, height: '1px' }}></div>
      </div>


      {
        isShowPagination && (
          <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
            <div className="col-sm">
              <div className="text-muted">
                {t('Total')}:
                {" "}
                <span className="fw-semibold ms-1">{formatNumberWithCommas(totalRecords)}</span>
                {" "}
                {t('Results')}
              </div>
            </div>
            <div className="col-sm-auto">
              <ReactPaginate
                nextLabel={`${t('next')} >`}
                onPageChange={(page) => {
                  if (handleChangePage) {
                    isScrollToTop && handleScrollToTop();
                    handleChangePage(page.selected);
                  }
                }}
                forcePage={+customPageIndex}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={customPageCount}
                previousLabel={`< ${t('previous')}`}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination pagination-separated"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </Row>)
      }

      <Modal
        isOpen={!!linkPayLetter}
        centered={true}
        size="md"
        toggle={() => setLinkPayLetter(() => null)}
        keyboard={true}
      >
        <ModalHeader toggle={() => setLinkPayLetter(() => null)}>
          {t("Show Receipt")}
        </ModalHeader>
        <ModalBody className="" >
          <div className="position-relative">
            <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }} ref={loadingPayLetterRef}>
              <Spinner className="text-primary" />
            </div>
            <div style={{ width: '100%', minHeight: '70dvh', maxHeight: '800px' }}>
              <iframe src={linkPayLetter || ''} style={{ width: '100%', minHeight: '70dvh', maxHeight: '800px' }}></iframe>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment >
  );
};

TableHistory.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default React.memo(TableHistory);