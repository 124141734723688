import authApi from "api/authApi";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, FormFeedback, Input, Spinner } from "reactstrap";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [timeOut, setTimeout] = useState<any>(0);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required(`${t("Please Enter Your Email")}`),
    }),
    onSubmit: (values) => {
      handleSubmitForgotPassword(values);
    },
  });
  const backToLogin = () => {
    navigation("/login");
  };
  const handleSubmitForgotPassword = async (values: any) => {
    try {
      setIsLoading(true);
      const res: any = await authApi.forgotPassword(values);
      if (res?.code === 200) {
        setTimeout(res?.data?.token_expire_time);
      }
    } catch (error: any) {
      toast.error(`${error || ""}`, CONFIG_OPTION_TOAST_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (timeOut <= 0) return;
    const timer = setInterval(function () {
      setTimeout((prev: any) => prev - 10);
      if (timeOut <= 0) {
        setTimeout(0);
        return;
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timeOut]);
  return (
    <>
      <div className="text-center mt-2">
        <h5 className="text-primary">{t("Forgot Password !")}</h5>
        <p className="text-muted">
          {t("Please enter your email and instructions will be sent to you.")}
        </p>
      </div>
      <div className="pb-4 px-0 mt-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Input
            name="email"
            type="email"
            className="form-control pe-5"
            placeholder={t("Enter Email")}
            onChange={validation.handleChange}
            value={validation.values.email || ""}
            invalid={
              validation.touched.email && validation.errors.email ? true : false
            }
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid">
              {validation.errors.email}
            </FormFeedback>
          ) : null}
          <div className="mt-4">
            <Button
              color="success"
              className="btn btn-success w-100"
              type="submit"
              disabled={!validation.values.email || timeOut > 0}
            >
              {isLoading && (
                <Spinner size="sm" className="me-2">
                  {" "}
                  {t("Loading")}...{" "}
                </Spinner>
              )}
              {timeOut ? `${t("Resend after")} ${timeOut / 10}s` : t("Submit")}
            </Button>
          </div>
        </Form>
        <div
          onClick={backToLogin}
          className="position-absolute fs-12 cursor-pointer start-0 bottom-0 my-2 mx-4 text-primary"
        >
          {" "}
          <FeatherIcon
            style={{ width: 12, height: 12 }}
            icon="arrow-left"
            className="icon-dual me-1 text-primary align-middle"
          />
          {t("Back to login")}
        </div>
      </div>{" "}
    </>
  );
};
export default ForgotPassword;
