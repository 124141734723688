import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Spinner,
} from "reactstrap";

import BreadCrumb from "components/Common/BreadCrumb";
import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import moment from "moment";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getGenders as onGetGenders } from "store/thunks";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import TooltipCustom from "components/Common/TooltipCustom";

const typeQuery = {
  keyword_count: StringParam,
};
function PaymentHistory() {
  const { t, i18n } = useTranslation();

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    keyword: StringParam,
    end_date: withDefault(StringParam, ""),
    start_date: withDefault(StringParam, ""),
    advertiser_id: StringParam,
    ...typeQuery,
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date
      ? moment(query?.start_date || "", "Y-MM-DD").toDate()
      : "",
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : "",
  ]);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState("");
  const [advertiserSearch, setAdvertiserSearch] = useState<any>();

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Gender;

  const userProperties = createSelector(selectLayoutState, (state) => ({
    genders: state.genders,
    isGenderSuccess: state.isGenderSuccess,
    isGenderLoading: state.isGenderLoading,
    error: state.error,
  }));

  // Inside your component
  const { genders, isGenderLoading } = useSelector(userProperties);

  useEffect(() => {
    dispatch(onGetGenders(query));
  }, [dispatch, query]);

  const columns = useMemo(
    () => [
      {
        Header: t("Campaign Name"),
        accessor: "name",
        filterable: false,
        sortable: false,
        thWidth: 200,
        thClass: 'align-middle',
      },
      {
        Header: t("News Title"),
        accessor: "news_title",
        filterable: false,
        sortable: false,
        thWidth: 200,
        thClass: 'align-middle',
      },
      {
        Header: t("Col_Gender"),
        accessor: "gender",
        filterable: false,
        sortable: false,
        thWidth: 200,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={2}>
                  {t('Col_Gender')}
                </th>
              </tr>
              <tr>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Col_Gender_Male')}</th>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Col_Gender_FeMale')}</th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const value: any = cell?.row?.original?.gender;
          const { female, male } = value;
          const num_male = Number(
            String(male).replace("%", "").replace(",", "")
          );

          const num_female = Number(
            String(female).replace("%", "").replace(",", "")
          );
          let nameTop: string = "";
          if (num_male > num_female) {
            nameTop = "naver_trend_male_ratio";
          } else if (num_male < num_female) {
            nameTop = "naver_trend_female_ratio";
          }
          return (
            <div className="d-flex text-center">
              <div style={{ width: '100px' }} className={`${nameTop === "naver_trend_male_ratio" && "text-danger"}`}>{num_male}%</div>
              <div style={{ width: '100px', borderLeft: "1px solid #eaeaea" }} className={`${nameTop === "naver_trend_female_ratio" && "text-danger"}`}>{num_female}%</div>
            </div>
          );
        },
      },
      {
        Header: t('Col_Age'),
        accessor: "age",
        thClass: 'text-end',
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 500,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={5}>
                  {t('Col_Age')}
                </th>
              </tr>
              <tr>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Col_Age_10')}</th>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Col_Age_20')}</th>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Col_Age_30')}</th>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Col_Age_40')}</th>
                <th className="text-center px-0 py-1" style={{ width: '100px' }}>{t('Col_Age_50')}</th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const value: any = cell?.row?.original?.age;
          const {
            10: age_10,
            20: age_20,
            30: age_30,
            40: age_40,
            50: age_50,
          } = value;
          const top = [age_10, age_20, age_30, age_40, age_50].reduce(
            ({ numMax = 0, i = 0 }, item: string, index) => {
              const num = Number(
                String(item).replace("%", "").replace(",", "")
              );
              const max =
                num > numMax ? { numMax: num, i: index + 1 } : { numMax, i };
              return max;
            },
            {}
          );

          return (
            <div className="d-flex text-center">
              <div style={{ width: '100px' }} className={`${top?.i === 1 && "text-danger"}`}>
                {age_10}%
              </div>
              <div style={{ width: '100px', borderLeft: "1px solid #eaeaea", }} className={`${top?.i === 2 && "text-danger"}`}>
                {age_20}%
              </div>
              <div style={{ width: '100px', borderLeft: "1px solid #eaeaea", }} className={`${top?.i === 3 && "text-danger"}`}>
                {age_30}%
              </div>
              <div style={{ width: '100px', borderLeft: "1px solid #eaeaea", borderRight: "1px solid #eaeaea" }} className={`${top?.i === 4 && "text-danger"}`}>
                {age_40}%</div>
              <div style={{ width: '100px' }} className={`${top?.i === 5 && "text-danger"}`}>
                {age_50}%</div>
            </div>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "published_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: "text-end align-middle",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: "text-end align-middle",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    setQuery({
      ...query,
      keyword: keyword,
      page: 1,
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      advertiser_id: advertiserSearch?.value,
    });
  };
  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };
  const resetData = () => {
    setQuery(
      {
        keyword: undefined,
        advertiser_id: undefined,
        start_date: undefined,
        end_date: undefined,
      },
      "push"
    );
    setKeyword("");
    setAdvertiserSearch(null);
    setDateSearch(["", ""]);
  };
  useEffect(() => {
    document.title = `${t("Gender & Age Analysis")} | Pr Console`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Gender & Age Analysis")}
            pageTitle={t("Analysis")}
          />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0 ">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2">
                    <Card
                      className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0 d-none d-md-block"
                      style={{ width: "200px" }}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                            <span className="counter-value text-primary">
                              {isGenderLoading ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                <CountUp
                                  start={0}
                                  end={genders?.pagination?.total || 0}
                                  duration={1}
                                />
                              )}
                            </span>
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <div className="align-items-end ">
                        <Row className="g-4 align-items-center">
                          <Col md={4} lg={3}>
                            <LabelWrapper
                              label={t("Search by Campaign, News title")}
                              isShow={keyword ? true : false}
                            >
                              <div className="search-box">
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t(
                                    "Search by Campaign, News title"
                                  )}...`}
                                  value={keyword}
                                  onChange={(e) => setKeyword(e.target.value)}
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </LabelWrapper>
                          </Col>
                          <Col
                            md={4}
                            lg={3}
                            className="date-picker-wrapper-custom"
                          >
                            <LabelWrapper
                              label={t("version_date")}
                              isShow={!!startDate || !!endDate}
                            >
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col lg={4} md={6} className="hstack gap-1">
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={isGenderLoading}
                              onClick={handleSearch}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={genders?.list?.length ? genders?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={genders?.pagination?.total}
                      customPageCount={Math.ceil(
                        genders?.pagination?.total / genders?.pagination?.limit
                      )}
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      handleChangePage={handleChangePage}
                      isLoading={isGenderLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PaymentHistory;
