import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IMedia } from "./types/_media";
var api = new APIClient();

const path = "/medias";
const mediaApi = {
  getMedias(
    params: any
  ): Promise<AxiosResponse<ResponseData<IMedia[]> & PaginationResponse, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },
  getMedia(
    id: string | number,
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params);
  },
  allMedias(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<IMedia[]>, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },
  postMedia(dataForm: any): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm);
  },
  putMedia(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm);
  },
  deleteMedia(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm);
  },

  createMedia(
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm);
  },
  putChangePasswordProfile(
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/change-password`;
    return api.put(url, dataForm);
  },
  putProfile(dataForm: any): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/profile`;
    return api.put(url, dataForm);
  },
};
export default mediaApi;
