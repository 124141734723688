import { useEffect, useRef, useState } from "react";
import { Spinner } from "reactstrap";

export default function CommentList({
  data,
  handlePostComment,
  isLoading,
}: any) {
  const [comments, setComment] = useState<any>();
  const containerRef = useRef<any>(null);
  const hanldeComment = () => {
    handlePostComment(comments);
    setComment("");
  };
  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [containerRef, data, isLoading]);
  return (
    <div className="p-4 card-body pt-0">
      <h5
        style={{ borderBottom: "1px solid #f0f0f0" }}
        className="card-title mb-0 pb-3"
      >
        Comments
      </h5>
      <div
        data-simplebar="init"
        className="px-3 mx-n3 simplebar-scrollable-y"
        style={{ height: 300 }}
      >
        <div
          ref={containerRef}
          className="simplebar-wrapper"
          style={{ margin: "0px -16px" }}
        >
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
              <div
                className="simplebar-content-wrapper"
                tabIndex={0}
                role="region"
                aria-label="scrollable content"
                style={{ height: "100%", overflow: "hidden scroll" }}
              >
                <div
                  className="simplebar-content "
                  style={{ padding: "0px 16px" }}
                >
                  {data?.map((item: any) => (
                    <div
                      className={`d-flex mb-3 mt-2 ${
                        item?.type === "advertiser"
                          ? "text-end gap-2 float-end flex-row-reverse"
                          : ""
                      }`}
                      style={{ width: "60%" }}
                    >
                      <div className="flex-shrink-0 d-flex align-items-end mb-n3">
                        <div className="avatar-xs rounded-circle bg-primary text-white d-flex justify-content-center align-items-center fs-10">
                          {item?.type === "advertiser" ? "Adv" : "Adm"}
                        </div>
                      </div>
                      <div
                        className={`flex-grow-1 ms-2 bg-primary-subtle px-3 py-2`}
                        style={{
                          borderRadius:
                            item?.type === "advertiser"
                              ? "10px 10px 0px 10px"
                              : "10px 10px 10px 0px",
                        }}
                      >
                        <h5
                          className={`d-flex fs-13 ${
                            item?.type === "advertiser"
                              ? "text-end gap-2 flex-row-reverse"
                              : ""
                          }`}
                        >
                          {item?.type}
                          <small className="text-muted ms-2">
                            {" "}
                            {item?.created_at}
                          </small>
                        </h5>
                        <p className="text-muted mb-0">{item?.content}</p>
                        {item?.attachments?.length > 0 && (
                          <div className="g-2 mb-3 row">
                            {item?.attachments?.map((item: any) => (
                              <div className="col-6 col-sm-2 col-lg-3">
                                <img
                                  src={item?.path}
                                  alt=""
                                  className="img-fluid rounded"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className="simplebar-placeholder"
            style={{ width: 797, height: 621 }}
          />
        </div>
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: "hidden" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ width: 0, display: "none" }}
          />
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: "visible" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{
              height: 144,
              transform: "translate3d(0px, 0px, 0px)",
              display: "block",
            }}
          />
        </div>
      </div>
      <form action="#" className="mt-3">
        <div className="g-3 row" style={{ borderTop: "1px solid #f0f0f0" }}>
          <div className="col-lg-12">
            <label
              htmlFor="exampleFormControlTextarea1"
              className="form-label form-label"
            >
              Leave a Comments
            </label>
            <textarea
              className="form-control bg-light border-light"
              id="exampleFormControlTextarea1"
              rows={3}
              placeholder="Enter comments"
              defaultValue={""}
              onChange={(e) => setComment(e.target.value)}
              value={comments}
            />
          </div>
          <div className="text-end col-lg-12">
            <button
              type="button"
              onClick={() => hanldeComment()}
              className="btn btn-primary"
            >
              {isLoading ? (
                <Spinner size={"sm"} className="me-2"></Spinner>
              ) : (
                <i className="ri-send-plane-fill me-2"></i>
              )}
              Post Comments
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
