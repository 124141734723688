import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import mediaApi from "api/mediaApi";
import { formatQueryParams } from "helpers/format";

export const getMedias = createAsyncThunk(
  "Medias",
  async (params: any = {}) => {
    try {
      const response = await mediaApi.getMedias(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllMedias = async (params: any = {}) => {
  try {
    const response = await mediaApi.allMedias(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getMedia = async (id: string | number, params: any = {}) => {
  try {
    const response = await mediaApi.getMedia(id, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const putMedia = async (id: string | number, data: any = {}) => {
  try {
    const response = await mediaApi.putMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteMedia = async (id: string | number, data: any = {}) => {
  try {
    const response = await mediaApi.deleteMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createMedia = async (data: any = {}) => {
  try {
    const response = await mediaApi.createMedia(data);
    return response;
  } catch (error) {
    return error;
  }
};
