import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
var api = new APIClient();

const path = "/usages";
const helpApi = {
  helps(
    params: any
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },
  getAdvertisers(
    id: string | number,
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params);
  },

  putHelp(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm);
  },
  deleteHelp(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm);
  },

  createHelp(dataForm: any): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = path;
    return api.post(url, dataForm);
  },
};
export default helpApi;
