import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import CampaignFormMulti from "./components/CampaignFormMulti";

const CampaignCreate = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = `${t("Campaign Create")} - ${t("Campaign")} | PRHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Campaign Create")} pageTitle={t("Campaign")} />
          <CampaignFormMulti />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CampaignCreate;
