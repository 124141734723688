import { createSlice } from "@reduxjs/toolkit";
import { IMedia } from "api/types/_media";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getMedias } from "./thunk";

export interface IState {
  medias: (ResponseData<IMedia[]> & PaginationResponse) | null;
  isMediaLoading: boolean;
  isMediaSuccess: boolean;

  allMedia: ResponseData<IMedia[]> | null;

  error: any;
}

export const initialState: IState = {
  medias: null,
  isMediaLoading: false,
  isMediaSuccess: false,

  allMedia: null,

  error: {},
};

const MediaSlice = createSlice({
  name: "Media",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Medias
    builder.addCase(getMedias.pending, (state: IState, action: any) => {
      state.isMediaLoading = true;
    });
    builder.addCase(getMedias.fulfilled, (state: IState, action: any) => {
      state.medias = action.payload.data;
      state.isMediaSuccess = true;
      state.isMediaLoading = false;
      state.allMedia = action.payload.data;
    });
    builder.addCase(getMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSuccess = false;
      state.isMediaLoading = false;
    });
  },
});

export default MediaSlice.reducer;
