import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { getSnapshot } from "store/thunks";
export default function NewsSnapshotModal({
  itemSnapshot,
  handleClose,
  className = "",
  title = "",
  enabledDataLabels = false,
}: any) {
  const [isLoadingGetSnapshot, setIsLoadingGetSnapshot] = useState(false);
  const [snapshot, setSnapshot] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("");

  const getSnapShot = async () => {
    try {
      setIsLoadingGetSnapshot(true);
      const res: any = await getSnapshot(itemSnapshot?.id);
      if (res?.code === 200) {
        const snapshotData = res?.data?.snapshots;

        setSnapshot(snapshotData);
      }
      setIsLoadingGetSnapshot(false);
    } catch (error) {
      setIsLoadingGetSnapshot(false);
    }
  };

  useEffect(() => {
    if (itemSnapshot?.id) {
      getSnapShot();
    }
    // eslint-disable-next-line
  }, [itemSnapshot]);
  useEffect(() => {
    if (snapshot && snapshot.length > 0) {
      setActiveTab(snapshot[0]?.media_name);
    }
  }, [snapshot]);

  return (
    <Modal
      isOpen={!!itemSnapshot}
      centered={true}
      size="xl"
      scrollable={true}
      toggle={() => {
        handleClose();
      }}
      keyboard={true}
      contentClassName={"h-100"}
    >
      <ModalHeader
        toggle={() => {
          handleClose();
        }}
      >
        {t("News Snapshot")}
      </ModalHeader>
      <ModalBody className="">
        {isLoadingGetSnapshot ? (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <Spinner size="lg" className="text-primary"></Spinner>
          </div>
        ) : (
          <>
            <Nav tabs>
              {snapshot?.map((item: any) => (
                <NavItem>
                  <NavLink
                    className={`border cursor-pointer ${activeTab === item?.media_name
                        ? "active text-white bg-primary"
                        : ""
                      }`}
                    onClick={() => setActiveTab(item?.media_name)}
                  >
                    {item?.media_name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {snapshot?.map((item: any) => (
                <TabPane tabId={item?.media_name}>
                  <Row>
                    <Col sm="12">
                      <Card className="p-3 gap-3">
                        <Row>
                          <Col xxl={12}>
                            <Row>
                              <h4 className="card-title mb-0 flex-grow-1 mb-2">
                                {t('Media')}:{"  "}
                                <span className="text-primary">
                                  {item?.media_name}
                                </span>
                              </h4>
                            </Row>
                            <Row>
                              <Col md={12} xxl={12}>
                                <Card className="mb-0">
                                  <img
                                    alt={item?.media_name}
                                    src={item?.image_url}
                                  ></img>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContent>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
