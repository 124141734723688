import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";

//pages
import ComingSoon from "../pages/Pages/ComingSoon/ComingSoon";
import Settings from "../pages/Pages/Profile/Settings/Settings";

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile

import Profile from "pages/User/Profile";
import UserProfile from "../pages/Authentication/user-profile";

import RoleList from "pages/User/Role";
import UserList from "pages/User/User";

import CampaignCreate from "pages/Campaign/CampaignCreate";
import Configuration from "pages/Configuration";
import CampaignList from "pages/Campaign/CampaignList";
import Charge from "pages/Point/Charge";
import ChargeHistory from "pages/Point/ChargeHistory";
import PaymentHistory from "pages/Point/PaymentHistory";
import GenderAndAge from "pages/Analysis/GenderAndAge";
import Keyword from "pages/Analysis/Keyword";
import Report from "pages/Report/Report";
import Export from "pages/Report/Export";
import Help from "pages/CustomerService/Help";
import Inquiry from "pages/CustomerService/Inquiry";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import ResetPassword from "pages/Authentication/ResetPassword";
import ForgotPassword from "pages/Authentication/ForgotPassword";

const ROUTES = {
  DASHBOARD: "/dashboard",

  PROFILE: "/profile",
  PROFILE_SETTING: "/pages-profile-settings",

  USER_LIST: "/user/list",
  USER_ROLE: "/user/role",
  USER_PROFILE: "/user/profile",

  CONFIGURATION: "/configuration",

  CAMPAIGN_CREATE: "/campaign-create",
  CAMPAIGN_LIST: "/campaign",

  CHARGE: "/charge",
  CHARGE_HISTORY: "/charge-history",
  PAYMENT_HISTORY: "/payment-history",

  GENDER_AGE_ANALYSIS: "/gender-age-analysis",
  KEYWORD_ANALYSIS: "/keyword-analysis",

  REPORT: "/report",
  SUMMARY_REPORT: "/summary-report",
  NEWS_SNAPSHOTS: "/news-snapshots",
  EXPORT: "/export",

  HELP: "/help",
  INQUIRY: "/inquiry",
};

const authProtectedRoutes = [
  { path: ROUTES.DASHBOARD, component: <DashboardCrm /> },

  { path: ROUTES.PROFILE_SETTING, component: <Settings /> },
  { path: ROUTES.PROFILE, component: <UserProfile /> },

  { path: ROUTES.USER_LIST, component: <UserList /> },
  { path: ROUTES.USER_ROLE, component: <RoleList /> },
  { path: ROUTES.USER_PROFILE, component: <Profile /> },

  { path: ROUTES.CONFIGURATION, component: <Configuration /> },

  { path: ROUTES.CAMPAIGN_CREATE, component: <CampaignCreate /> },
  { path: ROUTES.CAMPAIGN_LIST, component: <CampaignList /> },

  { path: ROUTES.CHARGE, component: <Charge /> },
  { path: ROUTES.CHARGE_HISTORY, component: <ChargeHistory /> },
  { path: ROUTES.PAYMENT_HISTORY, component: <PaymentHistory /> },

  { path: ROUTES.GENDER_AGE_ANALYSIS, component: <GenderAndAge /> },
  { path: ROUTES.KEYWORD_ANALYSIS, component: <Keyword /> },

  { path: ROUTES.REPORT, component: <Report /> },
  { path: ROUTES.EXPORT, component: <Export /> },

  { path: ROUTES.HELP, component: <Help /> },
  { path: ROUTES.INQUIRY, component: <Inquiry /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { ROUTES, authProtectedRoutes, publicRoutes };
