import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Container, Spinner } from "reactstrap";

import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import BreadCrumb from "components/Common/BreadCrumb";
import { useBalance } from "components/Hooks/UserHooks";
import { useFormik } from "formik";
import { formatNumber } from "helpers/constans";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import PopupPaymentCancel from "./components/PopupPaymentCancel";
import PopupPaymentFailed from "./components/PopupPaymentFailed";
import PopupPaymentSuccess from "./components/PopupPaymentSuccess";
import { createPaymentPort } from "store/thunks";
import {
  formatNumberWithCommas,
  formatNumberWithoutCommas,
} from "helpers/format";

function PointCharge({ initialValues }: any) {
  const { t, i18n } = useTranslation();
  const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
  const { userBalance } = useBalance();
  const location = window.location.protocol + "//" + window.location.host;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isShowPopupPaymentSuccess, setIsShowPopupPaymentSuccess] =
    useState(false);
  const [isShowPopupPaymentFailed, setIsShowPopupPaymentFailed] =
    useState(false);
  const [isShowPopupPaymentCancel, setIsShowPopupPaymentCancel] =
    useState(false);
  const [onlineUrl, setOnlineUrl] = useState("");
  const [detailPayment, setDetailPayment] = useState({});

  const success_url = `${location}/charge?payment_status=success`;
  const failed_url = `${location}/charge?payment_status=failed`;
  const cancel_url = `${location}/charge?payment_status=cancel`;
  const validationSchema = Yup.object({
    point: Yup.number()
      .min(1000)
      .required(`${t("Point is required")}`),
    method: Yup.string().required(`${t("Method is required")}`),
  });
  const handleSubmit = async (values: any) => {
    const params = {
      point: Number(values?.point),
      method: values?.method,
      success_url: success_url + `&point=${values.point}`,
      failed_url: failed_url,
      cancel_url: cancel_url,
    };
    try {
      setIsLoadingCreate(true);
      const res: any = await createPaymentPort(params);
      if (res?.code === 200) {
        setOnlineUrl(res?.data?.online_url);
        // formik?.setFieldValue("point", "");
      }
      setIsLoadingCreate(false);
    } catch (error: any) {
      setIsLoadingCreate(false);
      toast.error(
        `${error?.message || error || ""}`,
        CONFIG_OPTION_TOAST_ERROR
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      point: "",
      method: "card",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (searchParams?.get("payment_status")) {
      if (searchParams?.get("payment_status") === "success") {
        setIsShowPopupPaymentSuccess(true);
        setDetailPayment({
          point: searchParams?.get("point"),
        });
      }
      if (searchParams?.get("payment_status") === "failed") {
        setIsShowPopupPaymentFailed(true);
        setDetailPayment({
          point: searchParams?.get("point"),
        });
      }
      if (searchParams?.get("payment_status") === "cancel") {
        setIsShowPopupPaymentCancel(true);
        setDetailPayment({
          point: searchParams?.get("point"),
        });
      }
      setSearchParams({});
    }
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if (onlineUrl) {
      window.open(onlineUrl, "_self");
    }
    // eslint-disable-next-line
  }, [onlineUrl]);

  useEffect(() => {
    document.title = `${t("Charge")} | Pr Console`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Charge")} pageTitle={t("Point")} />
          <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
              <Col lg={12}>
                <Card id="leadsList">
                  <CardBody className="p-2">
                    <div
                      className="d-flex flex-column gap-3 h-100"
                      style={{
                        backgroundColor: "var(--vz-topbar-search-bg)",
                        padding: "20px",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="tab-content">
                        <div id="pills-bill-info" className="tab-pane active">
                          <div>
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    {t("Your Balance")}
                                  </label>
                                  <div className="text-primary fs-4 fw-semibold">
                                    {formatNumberWithCommas(
                                      userBalance?.point || 0
                                    )}
                                    <span className="ms-2 text-muted fs-6 fw-normal">
                                      {t("Point")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    {t("Point")}
                                    <span className="text-danger"> *</span>
                                  </label>
                                  <input
                                    id="billinginfo-lastName"
                                    placeholder={t("Point")}
                                    type="text"
                                    className="form-control form-control"
                                    onChange={(e) =>
                                      formik?.setFieldValue(
                                        "point",
                                        formatNumberWithoutCommas(
                                          e?.target?.value || ""
                                        )
                                      )
                                    }
                                    value={formatNumberWithCommas(
                                      formik?.values?.point || ""
                                    )}
                                  />
                                  {formik?.touched?.point &&
                                    formik?.errors?.point ? (
                                    <div className="text-danger mt-2">
                                      {" "}
                                      {formik.errors.point as string}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="row"></div>
                            <div className="mb-3">
                              <label className="form-label">
                                {t("Payment Method")}
                                <span className="text-danger"> *</span>
                              </label>
                              <div className="tab-pane active">
                                <div>
                                  <p className="text-muted mb-4">
                                    {t(
                                      "Please select and enter your billing information"
                                    )}
                                  </p>
                                </div>
                                <div className="g-4 row">
                                  <div className="col-sm-6 col-lg-4">
                                    <div>
                                      <div className="form-check card-radio">
                                        <input
                                          id="paymentMethod01"
                                          name="paymentMethod"
                                          type="radio"
                                          className="form-check-input form-check-input"
                                          checked={formik?.values?.method === 'bank_transfer'}
                                          onChange={(e) => {
                                            if (e?.isTrusted) {
                                              formik?.setFieldValue(
                                                "method",
                                                "bank_transfer"
                                              );
                                            }
                                          }}
                                        />
                                        <label
                                          htmlFor="paymentMethod01"
                                          className="form-check-label form-label"
                                        >
                                          <span className="fs-16 text-muted me-2">
                                            <i className="ri-bank-fill align-bottom" />
                                          </span>
                                          <span className="fs-14 text-wrap">
                                            {t("Bank Transfer")}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-lg-4">
                                    <div>
                                      <div className="form-check card-radio">
                                        <input
                                          id="paymentMethod02"
                                          name="paymentMethod"
                                          type="radio"
                                          className="form-check-input form-check-input"
                                          checked={formik?.values?.method === 'card'}
                                          onChange={(e) => {
                                            if (e?.isTrusted) {
                                              formik?.setFieldValue(
                                                "method",
                                                "card"
                                              );
                                            }
                                          }}
                                        />
                                        <label
                                          htmlFor="paymentMethod02"
                                          className="form-check-label form-label"
                                        >
                                          <span className="fs-16 text-muted me-2">
                                            <i className="ri-bank-card-fill align-bottom" />
                                          </span>
                                          <span className="fs-14 text-wrap">
                                            {t("Credit / Debit Card")}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {formik?.touched?.method &&
                                  formik?.errors?.method ? (
                                  <div className="text-danger mt-2">
                                    {" "}
                                    {formik.errors.method as string}
                                  </div>
                                ) : null}
                                {/* <div
                                  className="collapse show"
                                  id="paymentmethodCollapse"
                                >
                                  <div className="p-4 border shadow-none mb-0 mt-4 card">
                                    <div className="gy-3 row">
                                      <div className="col-md-12">
                                        <label
                                          htmlFor="cc-name"
                                          className="form-label form-label"
                                        >
                                          Name on card
                                        </label>
                                        <input
                                          id="cc-name"
                                          placeholder="Enter name"
                                          type="text"
                                          className="form-control form-control"
                                        />
                                        <small className="text-muted">
                                          Full name as displayed on card
                                        </small>
                                      </div>
                                      <div className="col-md-6">
                                        <label
                                          htmlFor="cc-number"
                                          className="form-label form-label"
                                        >
                                          Credit card number
                                        </label>
                                        <input
                                          id="cc-number"
                                          placeholder="xxxx xxxx xxxx xxxx"
                                          type="text"
                                          className="form-control form-control"
                                        />
                                      </div>
                                      <div className="col-md-3">
                                        <label
                                          htmlFor="cc-expiration"
                                          className="form-label form-label"
                                        >
                                          Expiration
                                        </label>
                                        <input
                                          id="cc-expiration"
                                          placeholder="MM/YY"
                                          type="text"
                                          className="form-control form-control"
                                        />
                                      </div>
                                      <div className="col-md-3">
                                        <label
                                          htmlFor="cc-cvv"
                                          className="form-label form-label"
                                        >
                                          CVV
                                        </label>
                                        <input
                                          id="cc-cvv"
                                          placeholder="xxx"
                                          type="text"
                                          className="form-control form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-muted mt-2 fst-italic">
                                    <i
                                      data-feather="lock"
                                      className="text-muted icon-xs"
                                    />{" "}
                                    Your transaction is secured with SSL
                                    encryption
                                  </div>
                                </div> */}
                              </div>
                            </div>

                            <div className="d-flex align-items-start gap-3 mt-3">
                              <button
                                disabled={isLoadingCreate}
                                type="submit"
                                className="btn btn-primary btn-label right ms-auto nexttab"
                              >
                                {isLoadingCreate ? (
                                  <Spinner size="sm" className="me-2"></Spinner>
                                ) : (
                                  <i className="lab la-stripe-s label-icon align-middle fs-16 ms-2"></i>
                                )}
                                {t("Topup")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </form>
          </React.Fragment>
        </Container>
      </div>
      <PopupPaymentSuccess
        isOpen={isShowPopupPaymentSuccess}
        handleClose={() => setIsShowPopupPaymentSuccess(false)}
        detailPayment={detailPayment}
      />
      <PopupPaymentFailed
        isOpen={isShowPopupPaymentFailed}
        handleClose={() => setIsShowPopupPaymentFailed(false)}
        detailPayment={detailPayment}
      />
      <PopupPaymentCancel
        isOpen={isShowPopupPaymentCancel}
        handleClose={() => setIsShowPopupPaymentCancel(false)}
        detailPayment={detailPayment}
      />
    </React.Fragment>
  );
}

export default PointCharge;
