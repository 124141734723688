import { APIClient } from "api/axiosClient";
import { ResponseData, PaginationResponse } from "api/types/_public";
import { AxiosResponse } from "axios";
var api = new APIClient();

const path = "/advertisers";
const advertiserApi = {
  advertisers(
    params: any
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },
  getAdvertisers(
    id: string | number,
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params);
  },

  putAdvertiser(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm);
  },
  deleteAdvertiser(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm);
  },

  createAdvertiser(
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `advertisers`;
    return api.post(url, dataForm);
  },
};
export default advertiserApi;
