import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getPayments } from "./thunk";

export interface IState {
  payments: (ResponseData<any[]> & PaginationResponse) | null;
  isPaymentLoading: boolean;
  isPaymentSuccess: boolean;

  allPayment: ResponseData<any[]> | null;

  error: any;
}

export const initialState: IState = {
  payments: null,
  isPaymentLoading: false,
  isPaymentSuccess: false,
  allPayment: null,
  error: {},
};

const packageSlice = createSlice({
  name: "Payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Packages
    builder.addCase(getPayments.pending, (state: IState, action: any) => {
      state.isPaymentLoading = true;
    });
    builder.addCase(getPayments.fulfilled, (state: IState, action: any) => {
      state.payments = action.payload.data;
      state.isPaymentSuccess = true;
      state.isPaymentLoading = false;
      state.allPayment = action.payload.data?.list;
    });
    builder.addCase(getPayments.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPaymentSuccess = false;
      state.isPaymentLoading = false;
    });
  },
});

export default packageSlice.reducer;
