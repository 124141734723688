import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Input,
} from "reactstrap";

import BreadCrumb from "components/Common/BreadCrumb";
import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { formatNumber } from "helpers/constans";
import moment from "moment";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getPayments as onGetPayments } from "store/thunks";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import TablePayment from "components/Common/TablePayment";

const typeQuery = {
  keyword_count: StringParam,
};

const SORT_BY_DEFAULT: string = 'date';
const ORDER_BY_DEFAULT: string = 'DESC';

function getStartAndEndOfWeek() {
  const today = new Date();

  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return ([startOfWeek, endOfWeek]);
}

function PaymentHistory() {
  const { t, i18n } = useTranslation();

  const [query, setQuery] = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    keyword: StringParam,
    start_date: withDefault(StringParam, moment(getStartAndEndOfWeek()[0]).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(getStartAndEndOfWeek()[1]).format("Y-MM-DD")),
    advertiser_id: StringParam,
    ...typeQuery,
  });


  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState("");
  const [advertiserSearch, setAdvertiserSearch] = useState<any>();

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Payment;

  const userProperties = createSelector(selectLayoutState, (state) => ({
    payments: state.payments,
    isPaymentSuccess: state.isPaymentSuccess,
    isPaymentLoading: state.isPaymentLoading,
    error: state.error,
  }));

  // Inside your component
  const { payments, isPaymentLoading } = useSelector(userProperties);

  useEffect(() => {
    dispatch(onGetPayments(query));
  }, [dispatch, query]);

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleSearch = () => {
    setQuery({
      ...query,
      time_request: + new Date(),
      keyword: keyword,
      page: 1,
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      advertiser_id: advertiserSearch?.value,
    });
  };
  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };
  const resetData = () => {
    const queryNew = {
      ...query,
      keyword: undefined,
      advertiser_id: undefined,
      start_date: moment(getStartAndEndOfWeek()[0]).format("Y-MM-DD"),
      end_date: moment(getStartAndEndOfWeek()[1]).format("Y-MM-DD"),
      time_request: + new Date(),
    };
    setQuery(queryNew, "push");
    setKeyword("");
    setAdvertiserSearch(null);
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
  };
  useEffect(() => {
    document.title = `${t("Payment History")} | Pr Console`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Payment History")} pageTitle={t("Point")} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0 ">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2">
                    <Card
                      className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0 d-none d-md-block"
                      style={{ width: "200px" }}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                            <span className="counter-value text-primary">
                              {isPaymentLoading ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                <CountUp
                                  start={0}
                                  end={payments?.pagination?.total || 0}
                                  duration={1}
                                />
                              )}
                            </span>
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <div className="align-items-end ">
                        <Row className="g-2 align-items-center">
                          {/* <Col md={4} lg={3}>
                            <LabelWrapper
                              label={t(`Campaign, News title`)}
                              isShow={keyword ? true : false}
                            >
                              <div className="search-box">
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t(
                                    "Search by Campaign, News title"
                                  )}...`}
                                  value={keyword}
                                  onChange={(e) => setKeyword(e.target.value)}
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </LabelWrapper>
                          </Col> */}
                          <Col
                            md={4} lg={3}
                            className="date-picker-wrapper-custom"
                          >
                            <LabelWrapper
                              label={t("version_date")}
                              isShow={!!dateSearch}
                            >
                              <></>
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col md={8} lg={9} className="hstack gap-1">
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={isPaymentLoading}
                              onClick={handleSearch}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TablePayment
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-have-first-row-is-total"
                      theadClass="table-light"
                      summary={payments?.summary}
                      data={payments?.list?.length ? payments?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={payments?.pagination?.total}
                      customPageCount={Math.ceil(Number(payments?.pagination?.total) / Number(payments?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isPaymentLoading}
                      isShowPagination={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PaymentHistory;
