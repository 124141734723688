export const formatNumber = (num: number) => {
  return new Intl.NumberFormat("en-IN").format(num);
};

export const formatNumberWithCommas = (number: number | string = 0) => {
  return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumberWithoutCommas = (number: number | string = 0) => {
  return parseFloat(String(number).replace(/,/g, ''));
};

export const formatDateStringTo2Row = (date: string = "") => {
  const arr = String(date).split(" ");
  return `${arr[0]} <br/> ${arr[1]}`;
};

export const formatQueryParams = (query: any = {}) => {
  const clone = structuredClone(query);
  delete clone.time_request;
  delete clone.time_request_out;
  delete clone.time_request_inner;
  return clone;
};
