import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import BreadCrumb from "components/Common/BreadCrumb";
import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import moment from "moment";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getKeywords as onGetKeywords } from "store/thunks";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { formatNumber } from "helpers/constans";
import TooltipCustom from "components/Common/TooltipCustom";

const typeQuery = {
  keyword_count: StringParam,
};
function KeywordList() {
  const { t, i18n } = useTranslation();
  const [openModalKeyword, setOpenModalKeyword] = useState(false);
  const [keywordsModal, setKeywordsModal] = useState<any[]>([]);
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    keyword: StringParam,
    end_date: withDefault(StringParam, ""),
    start_date: withDefault(StringParam, ""),
    advertiser_id: StringParam,
    ...typeQuery,
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date
      ? moment(query?.start_date || "", "Y-MM-DD").toDate()
      : "",
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : "",
  ]);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState("");
  const [advertiserSearch, setAdvertiserSearch] = useState<any>();

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Keyword;

  const userProperties = createSelector(selectLayoutState, (state) => ({
    keywords: state.keywords,
    isKeywordSuccess: state.isKeywordSuccess,
    isKeywordLoading: state.isKeywordLoading,
    error: state.error,
  }));

  // Inside your component
  const { keywords, isKeywordLoading } = useSelector(userProperties);

  const showModalKeywords = (data: any) => {
    setOpenModalKeyword(true);
    setKeywordsModal(data);
  };

  useEffect(() => {
    dispatch(onGetKeywords(query));
  }, [dispatch, query]);

  const columns = useMemo(
    () => [
      {
        Header: t("Campaign Name"),
        accessor: "name",
        filterable: false,
        sortable: false,
        thWidth: 200,
        thClass: 'align-middle',
      },
      {
        Header: t("News Title"),
        accessor: "news_title",
        filterable: false,
        sortable: false,
        thWidth: 200,
        thClass: 'align-middle',
      },
      {
        Header: t("Published Date"),
        accessor: "published_at",
        filterable: true,
        sortable: false,
        thWidth: 200,
        thClass: 'align-middle',
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t("PV"),
        accessor: "pv",
        filterable: true,
        sortable: false,
        thClass: "text-center align-middle",
        Cell: (cell: any) => (
          <div className="text-center">{formatNumber(cell?.value)}</div>
        ),
      },
      {
        Header: t("UV"),
        accessor: "uv",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: "text-center align-middle",
        Cell: (cell: any) => (
          <div className="text-center">{formatNumber(cell?.value)}</div>
        ),
      },
      {
        Header: t("Referrer Rate"),
        thClass: "text-center align-middle",
        filterable: false,
        sortable: false,
        // description: t('Referrer Rate'),
        thWidth: 300,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1" colSpan={3}>
                  {t("Referrer Rate")}
                  <TooltipCustom
                    title={t("Referrer Rate")}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: "translateY(2px)" }}
                  >
                    <i className="ri-question-line align-bottom text-secondary"></i>
                  </TooltipCustom>
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1"
                  style={{ width: "100px" }}
                >
                  {t("Visitor Naver")}
                </th>
                <th
                  className="text-center px-0 py-1"
                  style={{ width: "100px" }}
                >
                  {t("Visitor Google")}
                </th>
                <th
                  className="text-center px-0 py-1"
                  style={{ width: "100px" }}
                >
                  {t("Visitor Etc")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original?.referrer_ratio;
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>{item.naver}%</div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                {item.google}%
              </div>
              <div style={{ width: "100px" }}>{item.etc}%</div>
            </div>
          );
        },
      },

      {
        Header: t("Inflow Keywords"),
        accessor: "inflow_keywords",
        filterable: true,
        sortable: false,
        thClass: "text-start align-middle",
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          return (
            <div
              style={{ minWidth: "100px" }}
              className="text-primary cursor-pointer"
            >
              {value?.inflow_keywords?.length > 2 ? (
                <div>
                  {value?.inflow_keywords?.slice(0, 2).map((item: any) => (
                    <div className="tag-style"> {item}</div>
                  ))}

                  <div>
                    + <span>{value?.inflow_keywords?.length - 2}</span>
                    <span
                      className="fw-medium fs-12"
                      onClick={() => showModalKeywords(value?.inflow_keywords)}
                    >
                      {" "}
                      {t("Show more")}
                    </span>
                  </div>
                </div>
              ) : (
                value?.inflow_keywords.map((item: any) => (
                  <div className="tag-style"> {item}</div>
                ))
              )}
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: "text-end align-middle",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    setQuery({
      ...query,
      keyword: keyword,
      page: 1,
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      advertiser_id: advertiserSearch?.value,
    });
  };
  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };
  const resetData = () => {
    setQuery(
      {
        keyword: undefined,
        advertiser_id: undefined,
        start_date: undefined,
        end_date: undefined,
      },
      "push"
    );
    setKeyword("");
    setAdvertiserSearch(null);
    setDateSearch(["", ""]);
  };
  useEffect(() => {
    document.title = `${t("Keyword")} | Pr Console`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Keyword")} pageTitle={t("Analysis")} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0 ">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2">
                    <Card
                      className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0 d-none d-md-block"
                      style={{ width: "200px" }}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                            <span className="counter-value text-primary">
                              {isKeywordLoading ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                <CountUp
                                  start={0}
                                  end={keywords?.pagination?.total || 0}
                                  duration={1}
                                />
                              )}
                            </span>
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <div className="align-items-end ">
                        <Row className="g-4 align-items-center">
                          <Col
                            md={4}
                            lg={3}
                          >
                            <LabelWrapper
                              label={t("Campaign, News Title")}
                              isShow={keyword ? true : false}
                            >
                              <div className="search-box">
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t(
                                    "Search by Campaign Name, Title"
                                  )}...`}
                                  value={keyword}
                                  onChange={(e) => setKeyword(e.target.value)}
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </LabelWrapper>
                          </Col>
                          <Col
                            md={4}
                            lg={3}
                            className="date-picker-wrapper-custom"
                          >
                            <LabelWrapper
                              label={t("version_date")}
                              isShow={!!startDate || !!endDate}
                            >
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col lg={4} md={12} className="hstack gap-1">
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={isKeywordLoading}
                              onClick={handleSearch}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </Col>
                          {/* <Col
                            lg={4}
                            md={4}
                            className="md-text-start text-end mb-xl-0 mb-2 d-block"
                          >
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={handleCreate}
                            >
                              <i className="ri-add-fill align-bottom me-1"></i>{" "}
                              {t("Create Advertiser")}
                            </button>
                          </Col> */}
                        </Row>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={keywords?.list?.length ? keywords?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={keywords?.pagination?.total}
                      customPageCount={Math.ceil(
                        keywords?.pagination?.total /
                        keywords?.pagination?.limit
                      )}
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      handleChangePage={handleChangePage}
                      isLoading={isKeywordLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={openModalKeyword}
          centered={true}
          size="xl"
          scrollable={true}
          toggle={() => {
            setOpenModalKeyword(false);
          }}
          keyboard={true}
        >
          <ModalHeader
            toggle={() => {
              setOpenModalKeyword(false);
            }}
          >
            {t("Inflow Keywords")}
          </ModalHeader>
          <ModalBody className="mt-0 py-0">
            <div className="card">
              <div className="card-body">
                <div className="row g-4 w-100">
                  <Col md={12} className="mt-2">
                    <div className="d-flex flex-row flex-wrap">
                      {keywordsModal?.map((item: any, index: number) => (
                        <ListGroupItem
                          key={index}
                          tag="label"
                          className="px-4 py-3 text-start item-keyword-suggestion"
                        >
                          <span className="text-muted">{index + 1}.</span>{" "}
                          {item}
                        </ListGroupItem>
                      ))}
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default KeywordList;
