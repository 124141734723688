import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getKeywords } from "./thunk";

export interface IState {
  keywords: (ResponseData<any[]> & PaginationResponse) | null;
  isKeywordLoading: boolean;
  isKeywordSuccess: boolean;

  allKeyword: ResponseData<any[]> | null;

  error: any;
}

export const initialState: IState = {
  keywords: null,
  isKeywordLoading: false,
  isKeywordSuccess: false,
  allKeyword: null,
  error: {},
};

const packageSlice = createSlice({
  name: "Keyword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Packages
    builder.addCase(getKeywords.pending, (state: IState, action: any) => {
      state.isKeywordLoading = true;
    });
    builder.addCase(getKeywords.fulfilled, (state: IState, action: any) => {
      state.keywords = action.payload.data;
      state.isKeywordSuccess = true;
      state.isKeywordLoading = false;
      state.allKeyword = action.payload.data?.list;
    });
    builder.addCase(getKeywords.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordSuccess = false;
      state.isKeywordLoading = false;
    });
  },
});

export default packageSlice.reducer;
