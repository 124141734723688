import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getCharges } from "./thunk";

export interface IState {
  charges: (ResponseData<any[]> & PaginationResponse) | null;
  isChargeLoading: boolean;
  isChargeSuccess: boolean;

  allCharge: ResponseData<any[]> | null;

  error: any;
}

export const initialState: IState = {
  charges: null,
  isChargeLoading: false,
  isChargeSuccess: false,
  allCharge: null,
  error: {},
};

const packageSlice = createSlice({
  name: "Charge",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Packages
    builder.addCase(getCharges.pending, (state: IState, action: any) => {
      state.isChargeLoading = true;
    });
    builder.addCase(getCharges.fulfilled, (state: IState, action: any) => {
      state.charges = action.payload.data;
      state.isChargeSuccess = true;
      state.isChargeLoading = false;
      state.allCharge = action.payload.data?.list;
    });
    builder.addCase(getCharges.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isChargeSuccess = false;
      state.isChargeLoading = false;
    });
  },
});

export default packageSlice.reducer;
