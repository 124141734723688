import { createAsyncThunk } from "@reduxjs/toolkit";
import reportApi from "api/reportApi";
import { formatQueryParams } from "helpers/format";

//Include Both Helper File with needed methods

export const getReports = createAsyncThunk(
  "Report",
  async (params: any = {}) => {
    try {
      const response = await reportApi.reports(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getSummaryReport = async (id: any) => {
  try {
    const response = await reportApi.getSummaryReport(id);
    return response;
  } catch (error) {
    return error;
  }
};

export const getSnapshot = async (id: any) => {
  try {
    const response = await reportApi.getSnapshot(id);
    return response;
  } catch (error) {
    return error;
  }
};
