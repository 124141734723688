import authApi from "api/authApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, FormFeedback, Input, Spinner } from "reactstrap";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";
import { push } from "helpers/navigation";

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(`${t("Please Enter Your Email")}`),
    }),
    onSubmit: (values) => {
      handleSubmitResetPassword(values);
    },
  });
  const backToLogin = () => {
    navigation("/login");
  };
  const handleSubmitResetPassword = async (values: any) => {
    try {
      setIsLoadingReset(true);
      const params = {
        reset_password_token: token,
        password: values.password,
      };
      const res: any = await authApi.resetPassword(params);
      if (res?.code === 200) {
        toast.success(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        setTimeout(() => {
          push("/login");
        }, 3000);
      }
    } catch (error: any) {
      toast.error(`${error || ""}`, CONFIG_OPTION_TOAST_ERROR);
    } finally {
      setIsLoadingReset(false);
    }
  };

  const verifyToken = async (token: string) => {
    try {
      setIsLoading(true);
      const param = {
        reset_password_token: token,
      };
      const res: any = await authApi.verifyResetToken(param);
      if (res?.code === 200) {
        setIsVerified(true);
      }
    } catch (error: any) {
      toast.error(`${error || ""}`, CONFIG_OPTION_TOAST_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      verifyToken(token);
    }
  }, [token]);

  return (
    <>
      <div className="text-center mt-2">
        {isLoading && (
          <div>
            <div className="mb-3">
              <Spinner
                type="grow"
                style={{ width: "28px", height: "28px" }}
                color="primary"
              />
            </div>
            <h5 className="text-primary">
              <strong>{t("Verifying token !")}</strong>
            </h5>
          </div>
        )}
        {isVerified && !isLoading && (
          <>
            <FeatherIcon icon="check-circle" className="text-success mb-3" />
            <h5 className="text-primary">{t("Reset Password !")}</h5>
            <p className="text-muted">{t("Please enter your new password.")}</p>
          </>
        )}
        {!isVerified && !isLoading && (
          <>
            <FeatherIcon icon="x-circle" className="text-danger mb-3" />
            <h5 className="text-danger">{t("Verify token error !")}</h5>
            <p className="text-muted">
              {t("Please contact to admin for help.")}
            </p>
          </>
        )}
      </div>
      {isVerified && !isLoading && (
        <div className="pb-4 px-0 mt-4">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <Input
              name="password"
              type="password"
              className="form-control pe-5"
              placeholder={t("Enter Password")}
              onChange={validation.handleChange}
              value={validation.values.password || ""}
              invalid={
                validation.touched.password && validation.errors.password
                  ? true
                  : false
              }
            />
            {validation.touched.password && validation.errors.password ? (
              <FormFeedback type="invalid">
                {validation.errors.password}
              </FormFeedback>
            ) : null}
            <div className="mt-4">
              <Button
                color="success"
                className="btn btn-success w-100"
                type="submit"
                disabled={!validation.values.password}
              >
                {isLoadingReset && (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    {t("Loading")}...{" "}
                  </Spinner>
                )}
                {t("Submit")}
              </Button>
            </div>
          </Form>
          <div
            onClick={backToLogin}
            className="position-absolute fs-12 cursor-pointer start-0 bottom-0 my-2 mx-4 text-primary"
          >
            {" "}
            <FeatherIcon
              style={{ width: 12, height: 12 }}
              icon="arrow-left"
              className="icon-dual me-1 text-primary align-middle"
            />
            {t("Back to login")}
          </div>
        </div>
      )}
    </>
  );
};
export default ResetPassword;
