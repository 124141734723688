import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionItem,
  Card,
  Col,
  Collapse,
  Container,
} from "reactstrap";
import { getHelpList } from "store/help/thunk";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";

const Help = () => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<any>();
  const [query] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 0),
  });
  const [open, setOpen] = useState<any>();
  const toggle = (id: any) => {
    if (open === id) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };

  const getHelps = async () => {
    try {
      setIsLoading(true);
      const res: any = await getHelpList(query);
      if (res?.code === 200) {
        setDataList(res?.data);
      } else {
        toast.error(`${res?.error || ""}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast.error(`${error || ""}`, CONFIG_OPTION_TOAST_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const listUsage = useMemo(() => {
    return dataList?.filter((item: any) => item?.type === "usage");
  }, [dataList]);

  const listFAQ = useMemo(() => {
    return dataList?.filter((item: any) => item?.type === "faq");
  }, [dataList]);
  useEffect(() => {
    document.title = `${t("Help")} - ${t("Customer Service")} | PRHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);
  useEffect(() => {
    getHelps();
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Help")} pageTitle={t("Customer Service")} />
          <Card className="py-2 px-4">
            <div>
              <div className="row">
                <div className="align-self-center col-xxl-5">
                  <p className="text-muted  fs-5 fw-semibold mt-3">
                    If you can not find answer to your question in our FAQ, you
                    can always contact us or email us. We will answer you
                    shortly!
                  </p>
                  <div className="hstack flex-wrap gap-2">
                    <button
                      type="button"
                      className="btn btn-primary btn-label rounded-pill"
                    >
                      <i className="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                      Email Us
                    </button>
                  </div>
                </div>
                <div className="col-xxl-3 ms-auto">
                  <div className="mb-n5 pb-1 faq-img d-none d-xxl-block">
                    <img
                      src="/velzon/react/minimal/static/media/faq-img.a1d026a43c2533e4455d.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-content-start my-4 row">
              <div className="col-lg-6">
                <div className="card-header px-1 border-0">
                  <div
                    style={{
                      borderLeft: "4px solid #25a0e299",
                      lineHeight: "1.2",
                    }}
                    className="card-title fw-semibold fs-4 text-primary ps-2"
                  >
                    Usage
                  </div>
                </div>
                {!isLoading && (
                  <Accordion
                    className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-primary"
                    open={open}
                    /* @ts-ignore */
                    toggle={toggle}
                  >
                    {listUsage?.map((item: any, index: number) => (
                      /* @ts-ignore */
                      <AccordionItem>
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button ${open === item?.id ? "" : "collapsed"
                              }`}
                            type="button"
                            onClick={() => toggle(item?.id)}
                            style={{ cursor: "pointer" }}
                          >
                            {item?.title}
                          </button>
                        </h2>
                        <Collapse
                          isOpen={open === item?.id}
                          className="accordion-collapse"
                        >
                          <div className="accordion-body">
                            <Col xxl={12} className="mb-4">
                              <div className="d-sm-flex h-100">
                                <div className="flex-shrink-0 cursor-pointer">
                                  <img
                                    src={item?.attachments[0]?.url}
                                    alt=""
                                    width="115"
                                    className="rounded-1"
                                  ></img>
                                </div>

                                <div className="flex-grow-1 d-flex flex-column ms-sm-4 mt-4 mt-sm-0">
                                  <div
                                    className="fs-6"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.content,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Collapse>
                      </AccordionItem>
                    ))}
                  </Accordion>
                )}
              </div>
              <div className="col-lg-6">
                <div className="card-header px-1 border-0">
                  <div
                    style={{
                      borderLeft: "4px solid #25a0e299",
                      lineHeight: "1.2",
                    }}
                    className="card-title fw-semibold fs-4 text-primary ps-2"
                  >
                    FAQ
                  </div>
                </div>
                {!isLoading && (
                  <Accordion
                    className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-primary"
                    open={open}
                    /* @ts-ignore */
                    toggle={toggle}
                  >
                    {listFAQ?.map((item: any, index: number) => (
                      /* @ts-ignore */
                      <AccordionItem>
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button ${open === item?.id ? "" : "collapsed"
                              }`}
                            type="button"
                            onClick={() => toggle(item?.id)}
                            style={{ cursor: "pointer" }}
                          >
                            {item?.title}
                          </button>
                        </h2>
                        <Collapse
                          isOpen={open === item?.id}
                          className="accordion-collapse"
                        >
                          <div className="accordion-body">
                            <Col xxl={12} className="mb-4">
                              <div className="d-sm-flex h-100">
                                <div className="flex-shrink-0 cursor-pointer">
                                  <img
                                    src={item?.attachments[0]?.url}
                                    alt=""
                                    width="115"
                                    className="rounded-1"
                                  ></img>
                                </div>

                                <div className="flex-grow-1 d-flex flex-column ms-sm-4 mt-4 mt-sm-0">
                                  <div
                                    className="fs-6"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.content,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Collapse>
                      </AccordionItem>
                    ))}
                  </Accordion>
                )}
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Help;
