import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//redux

import { Link, Outlet } from "react-router-dom";
// Formik validation

// actions

import logoDark from "assets/images/logo-newshub-dark.png";
import ParticlesAuth from "pages/AuthenticationInner/ParticlesAuth";
import { useTranslation } from "react-i18next";
//import images

const AuthLayout = (props: any) => {
  const { t } = useTranslation();

  // Inside your component

  // useEffect(() => {
  //     setDeviceId(getDeviceId());
  // }, [])

  document.title = `PRHub - ${t("Sign In")}`;

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoDark} alt="" height="30" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">
                    {t("PRHub Dashboard")}
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card
                  className="mt-4"
                  style={{
                    boxShadow: "0px 0px 20px 0px rgba(76, 87, 125, 0.02)",
                  }}
                >
                  <CardBody className="p-4">
                    <Outlet />
                  </CardBody>
                </Card>
                {/* 
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default AuthLayout;
