import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getPackages } from "./thunk";

export interface IState {
  packages: (ResponseData<any[]> & PaginationResponse) | null;
  isPackageLoading: boolean;
  isPackageSuccess: boolean;

  allPackage: ResponseData<any[]> | null;

  error: any;
}

export const initialState: IState = {
  packages: null,
  isPackageLoading: false,
  isPackageSuccess: false,
  allPackage: null,
  error: {},
};

const packageSlice = createSlice({
  name: "Package",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Packages
    builder.addCase(getPackages.pending, (state: IState, action: any) => {
      state.isPackageLoading = true;
    });
    builder.addCase(getPackages.fulfilled, (state: IState, action: any) => {
      state.packages = action.payload.data;
      state.isPackageSuccess = true;
      state.isPackageLoading = false;
      state.allPackage = action.payload.data;
    });
    builder.addCase(getPackages.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPackageSuccess = false;
      state.isPackageLoading = false;
    });
  },
});

export default packageSlice.reducer;
