import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import { formatQueryParams } from "helpers/format";
import advertiserApi from "../../api/advertiserApi";

export const getAdvertisers = createAsyncThunk(
  "Advertiser",
  async (params: any = {}) => {
    try {
      const response = await advertiserApi.advertisers(
        formatQueryParams(params)
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const postAdvertiser = async (data: any = {}) => {
  try {
    const response = await advertiserApi.createAdvertiser(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putAdvertiser = async (id: string | number, data: any = {}) => {
  try {
    const response = await advertiserApi.putAdvertiser(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAdvertiser = async (id: string | number, data: any = {}) => {
  try {
    const response = await advertiserApi.deleteAdvertiser(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createAdvertiser = async (data: any = {}) => {
  try {
    const response = await advertiserApi.createAdvertiser(data);
    return response;
  } catch (error) {
    return error;
  }
};
