import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
var api = new APIClient();

const path = "/inquiries";
const inquiryApi = {
  inquiries(
    params: any
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },

  getDetail(
    id: string | number
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/${id}`;
    return api.get(url);
  },

  updateStatus(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm);
  },
  deleteInquiry(
    id: string | number
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url);
  },

  postInquiry(dataForm: any): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm);
  },

  postComment(
    id: any,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `${path}/reply/${id}`;
    return api.post(url, dataForm);
  },
};
export default inquiryApi;
