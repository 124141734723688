import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import packageApi from "api/packageApi";
import { formatQueryParams } from "helpers/format";

export const getPackages = createAsyncThunk(
  "Package",
  async (params: any = {}) => {
    try {
      const response = await packageApi.packages(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAllPackages = async (id: string | number, params: any = {}) => {
  try {
    const response = await packageApi.getPackages(id, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const postPackage = async (data: any = {}) => {
  try {
    const response = await packageApi.createPackage(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putPackage = async (id: string | number, data: any = {}) => {
  try {
    const response = await packageApi.putPackage(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deletePackage = async (id: string | number, data: any = {}) => {
  try {
    const response = await packageApi.deletePackage(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createPackage = async (data: any = {}) => {
  try {
    const response = await packageApi.createPackage(data);
    return response;
  } catch (error) {
    return error;
  }
};
