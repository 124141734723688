import { createAsyncThunk } from "@reduxjs/toolkit";
import paymentApi from "api/paymentApi";
import { formatQueryParams } from "helpers/format";

//Include Both Helper File with needed methods

export const getPayments = createAsyncThunk(
  "Payment",
  async (params: any = {}) => {
    try {
      const response = await paymentApi.payments(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);
