import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getAdvertisers } from "./thunk";

export interface IState {
  advertisers: (ResponseData<any[]> & PaginationResponse) | null;
  isAdvertiserLoading: boolean;
  isAdvertiserSuccess: boolean;

  allAdvertiser: ResponseData<any[]> | null;

  error: any;
}

export const initialState: IState = {
  advertisers: null,
  isAdvertiserLoading: false,
  isAdvertiserSuccess: false,
  allAdvertiser: null,
  error: {},
};

const packageSlice = createSlice({
  name: "Advertiser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Packages
    builder.addCase(getAdvertisers.pending, (state: IState, action: any) => {
      state.isAdvertiserLoading = true;
    });
    builder.addCase(getAdvertisers.fulfilled, (state: IState, action: any) => {
      state.advertisers = action.payload.data;
      state.isAdvertiserSuccess = true;
      state.isAdvertiserLoading = false;
      state.allAdvertiser = action.payload.data?.list;
    });
    builder.addCase(getAdvertisers.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdvertiserSuccess = false;
      state.isAdvertiserLoading = false;
    });
  },
});

export default packageSlice.reducer;
