import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import BreadCrumb from "components/Common/BreadCrumb";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import TableHistory from "components/Common/TableHistory";
import { PAYMENT_METHOD_OPTIONS } from "helpers/constans";
import moment from "moment";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getCharges as onGetCharges } from "store/thunks";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

const typeQuery = {
  keyword_count: StringParam,
};

const SORT_BY_DEFAULT: string = 'date';
const ORDER_BY_DEFAULT: string = 'DESC';

function getStartAndEndOfWeek() {
  const today = new Date();

  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return ([startOfWeek, endOfWeek]);
}

function ChargeHistory() {
  const { t, i18n } = useTranslation();

  const [query, setQuery] = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    keyword: StringParam,
    start_date: withDefault(StringParam, moment(getStartAndEndOfWeek()[0]).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(getStartAndEndOfWeek()[1]).format("Y-MM-DD")),
    method: StringParam,
    ...typeQuery,
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState("");
  const [methodSearch, setMethodSearch] = useState<any>(PAYMENT_METHOD_OPTIONS?.filter((item) => String(item?.value) === String(query?.method))[0]);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Charge;

  const userProperties = createSelector(selectLayoutState, (state) => ({
    charges: state.charges,
    isChargeSuccess: state.isChargeSuccess,
    isChargeLoading: state.isChargeLoading,
    error: state.error,
  }));

  // Inside your component
  const { charges, isChargeLoading } = useSelector(userProperties);

  useEffect(() => {
    dispatch(onGetCharges(query));
  }, [dispatch, query]);

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleSearch = () => {
    setQuery({
      ...query,
      time_request: + new Date(),
      keyword: keyword,
      page: 1,
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      method: methodSearch?.value,
    });
  };
  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };
  const resetData = () => {
    const queryNew = {
      ...query,
      keyword: undefined,
      start_date: moment(getStartAndEndOfWeek()[0]).format("Y-MM-DD"),
      end_date: moment(getStartAndEndOfWeek()[1]).format("Y-MM-DD"),
      method: undefined,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setKeyword("");
    setMethodSearch(null);
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
  };


  useEffect(() => {

    if (methodSearch) {
      setMethodSearch((_prev: any) =>
        PAYMENT_METHOD_OPTIONS?.filter((e: any) => e.value === methodSearch?.value)[0]
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t("Charge History")} | Pr Console`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Charge History")} pageTitle={t("Point")} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0 ">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2">
                    <Card
                      className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0 d-none d-md-block"
                      style={{ width: "200px" }}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                            <span className="counter-value text-primary">
                              {isChargeLoading ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                <CountUp
                                  start={0}
                                  end={charges?.pagination?.total || 0}
                                  duration={1}
                                />
                              )}
                            </span>
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <div className="align-items-end ">
                        <Row className="g-4 align-items-center">
                          <Col
                            md={4}
                            lg={3}
                            className="date-picker-wrapper-custom"
                          >
                            <LabelWrapper
                              label={t("version_date")}
                              isShow={!!dateSearch}
                            >
                              <></>
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                showOptions={[
                                  'today', 'yesterday',
                                  'this_week',
                                  'this_month', 'last_month', 'last_3_month', 'last_6_month',
                                ]}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col md={4} lg={3}>
                            <LabelWrapper
                              label={t(`Payment Method`)}
                              isShow={methodSearch?.value ? true : false}
                            >
                              <DropdownOption
                                name="media_id"
                                dataList={PAYMENT_METHOD_OPTIONS || []}
                                placeholder={`${t("Payment Method")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={methodSearch || null}
                                onChangeSelect={(e: any) => setMethodSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{
                                  label: t("All Method"),
                                  value: "",
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col lg={4} md={6} className="hstack gap-1">
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={isChargeLoading}
                              onClick={handleSearch}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableHistory
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-have-first-row-is-total"
                      theadClass="table-light"
                      summary={charges?.summary}
                      data={charges?.list?.length ? charges?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={charges?.pagination?.total}
                      customPageCount={Math.ceil(Number(charges?.pagination?.total) / Number(charges?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isChargeLoading}
                      isShowPagination={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ChargeHistory;
