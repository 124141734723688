import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";

const Export = () => {
    const { t, i18n } = useTranslation();

    const handleCallAllOption = async () => {
        try {
            const []: any = await Promise.all([]);

        } catch (error: any) {
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = `${t("Export")} - ${t("Report")} | PRHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title={t("Export")}
                        pageTitle={t("Report")}
                    />
                    {/* Code here  */}
                    <div className="text-center my-4">
                        {t('Is in the process of development')}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Export;