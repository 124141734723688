import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Spinner,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

// actions
import { loginUser, resetLoginFlag } from "../../store/thunks";

import { useTranslation } from "react-i18next";
import { createSelector } from "reselect";
import { StringParam, useQueryParam } from "use-query-params";
//import images

const Login = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [redirectFrom] = useQueryParam("redirectFrom", StringParam);
  const dispatch = useDispatch<any>();
  const selectLayoutState = (state: any) => state;
  const loginpageData = createSelector(selectLayoutState, (state) => ({
    user: state.Account.user,
    error: state.Login.error,
    errorMsg: state.Login.errorMsg,
  }));
  // Inside your component
  //eslint-disable-next-line
  const { user, error, errorMsg } = useSelector(loginpageData);
  //eslint-disable-next-line
  const [userLogin, setUserLogin] = useState<any>([]);
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  // const [deviceId, setDeviceId] = useState<string>('');

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || "",
      password: userLogin.password || "",
      // device_id: deviceId || ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required(`${t("Please Enter Your Email")}`),
      password: Yup.string().required(`${t("Please Enter Your Password")}`),
      // device_id: Yup.string()
    }),
    onSubmit: (values) => {
      dispatch(
        loginUser(
          values,
          props.router.navigate,
          redirectFrom ? redirectFrom : "/dashboard"
        )
      );
      setLoader(true);
    },
  });

  const handleToForgotPassWordPage = () => {
    navigate("/forgot-password");
  };

  useEffect(() => {
    let authUser: any = localStorage.getItem("authUser");
    if (authUser) {
      props?.router?.navigate("dashboard");
    }

    //eslint-disable-next-line
  }, [redirectFrom]);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
        setLoader(false);
      }, 1000);
    }
  }, [dispatch, errorMsg]);

  // useEffect(() => {
  //     setDeviceId(getDeviceId());
  // }, [])

  document.title = `PR Console - ${t("Sign In")}`;

  return (
    <React.Fragment>
      <div className="text-center mt-2">
        <h5 className="text-primary">{t("Welcome Back !")}</h5>
        <p className="text-muted">
          {t("Sign in to continue to PR Console System.")}
        </p>
      </div>
      <div className="p-2 mt-4">
        {error && error ? <Alert color="danger "> {error} </Alert> : null}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <div className="mb-3">
            <Label htmlFor="email" className="form-label">
              {t("Email")}
            </Label>
            <Input
              name="email"
              className="form-control"
              placeholder={t("Enter email")}
              type="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            {/* <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                    </div> */}
            <Label className="form-label" htmlFor="password-input">
              {t("Password")}
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="password"
                value={validation.values.password || ""}
                type={passwordShow ? "text" : "password"}
                className="form-control pe-5"
                placeholder={t("Enter Password")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                onClick={() => setPasswordShow(!passwordShow)}
                type="button"
                id="password-addon"
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </div>
          <div
            onClick={handleToForgotPassWordPage}
            className="fs-12 mb-3 d-inline-block text-primary float-end cursor-pointer"
          >
            {t("Forgot Password")}
          </div>
          {/* <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                </div> */}

          <div className="mt-4">
            <Button
              color="success"
              disabled={loader && true}
              className="btn btn-success w-100"
              type="submit"
            >
              {loader && (
                <Spinner size="sm" className="me-2">
                  {" "}
                  {t("Loading")}...{" "}
                </Spinner>
              )}
              {t("Sign In")}
            </Button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
