import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Input, Label, Row, Spinner } from "reactstrap";
import { getRole, postRole, putRole } from "store/thunks";

//import images
import { IPermission } from "api/types/_role";

export interface Tag {
  id: string;
  text: string;
}

export interface Props {
  isModal?: boolean;
  id?: string;
  isCopy?: boolean;
  permissions?: IPermission[];
  triggerRefresh?: () => void;
  onCloseClick?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const RoleForm = ({
  isModal = false,
  id = "",
  isCopy = false,
  permissions = [],
  triggerRefresh,
  onCloseClick,
}: Props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
  const [nameRole, setNameRole] = useState<string>("");
  const [descriptionRole, setDescriptionRole] = useState<string>("");
  const [listSelected, setListSelected] = useState<number[]>([]);

  const handleSubmit = async () => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        name: nameRole || "",
        description: descriptionRole || "",
        permissions: listSelected || [],
      };
      const response: any = id ? await putRole(id, data) : await postRole(data);
      if (response?.data) {
        setIsLoading((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        triggerRefresh && triggerRefresh();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleCallAllOption = async (idItem: string) => {
    try {
      if (!idItem) {
        return;
      }
      setIsLoadingDetail((_prev) => true);
      const [resDetail]: any = await Promise.all([
        idItem ? getRole(idItem) : {},
      ]);
      if (resDetail?.data) {
        setNameRole((_prev) => resDetail?.data?.name || "");
        setDescriptionRole((_prev) => resDetail?.data?.description || "");
        setListSelected((_prev) =>
          (resDetail?.data?.permissions || [])?.map((item: any) => item?.id)
        );
        setIsLoadingDetail((_prev) => false);
      }
    } catch (error: any) {
      setIsLoadingDetail((_prev) => false);
      return error;
    }
  };

  const handleCheckPermission = (
    event: any,
    sub1: number | null = null,
    sub2: number | null = null,
    sub3: number | null = null
  ) => {
    const isChecked = event.target.checked;

    if (sub2 !== null && sub1 !== null) {
      const val: number = ((permissions as any) || [])[sub1]?.permissions[sub2]
        ?.id;
      setListSelected((prev) => {
        return isChecked
          ? [...prev, val]
          : prev?.filter((item) => ![val]?.includes(item));
      });
      return;
    }
    if (sub1 !== null) {
      const val: number[] =
        ((permissions as any) || [])[sub1]?.permissions?.map((item: any) =>
          Number(item?.id)
        ) || [];
      setListSelected((prev) => {
        return isChecked
          ? [...prev, ...val]
          : prev?.filter((item) => !val?.includes(item));
      });
      return;
    }
    if (sub1 === null) {
      const val: number[] = ((permissions as any) || [])
        ?.map((sub: any) => {
          return sub?.permissions?.map((item: any) => Number(item?.id)) || [];
        })
        .flat();
      setListSelected((prev) => {
        return isChecked ? [...prev, ...val] : [];
      });
      return;
    }
  };

  const isChecked = (
    sub: string,
    sub1: number | null = null,
    sub2: number | null = null,
    sub3: number | null = null
  ) => {
    if (sub === "SUB2" && sub2 !== null && sub1 !== null) {
      const val: number = ((permissions as any) || [])[sub1]?.permissions[sub2]
        ?.id;
      return [val]?.reduce((result: boolean, item: number) => {
        return result && listSelected?.includes(Number(item));
      }, true);
    }
    if (sub === "SUB1" && sub1 !== null) {
      const val: number[] =
        ((permissions as any) || [])[sub1]?.permissions?.map((item: any) =>
          Number(item?.id)
        ) || [];
      return val?.reduce((result: boolean, item: number) => {
        return result && listSelected?.includes(Number(item));
      }, true);
    }
    if (sub === "SUB0") {
      const val: number[] = ((permissions as any) || [])
        ?.map((sub: any) => {
          return sub?.permissions?.map((item: any) => Number(item?.id)) || [];
        })
        .flat();
      return val?.reduce((result: boolean, item: number) => {
        return result && listSelected?.includes(Number(item));
      }, true);
    }
    return false;
  };

  useEffect(() => {
    handleCallAllOption(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <React.Fragment>
      {isLoadingDetail && (
        <div
          style={{
            position: "absolute",
            zIndex: 3,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgb(164 164 164 / 36%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="sm" color="primary"></Spinner>
        </div>
      )}
      <div>
        <Container fluid>
          <Row className="align-items-end mb-4">
            <Col sm={6} md={4} lg={4}>
              <Label htmlFor="roleNameInput" className="form-label">
                {t("Name")} <span className="text-danger"> *</span>
              </Label>
              <Input
                id="roleNameInput"
                type="text"
                className="form-control search"
                placeholder={`${t("Name")}...`}
                value={nameRole}
                onChange={(e) => setNameRole((prev) => e.target.value)}
              />
            </Col>
            <Col sm={6} md={5} lg={5} className="mt-2">
              <Label htmlFor="roleDescriptionInput" className="form-label">
                {t("Description")}
              </Label>
              <Input
                id="roleDescriptionInput"
                type="text"
                className="form-control search"
                placeholder={`${t("Description")}...`}
                value={descriptionRole}
                onChange={(e) => setDescriptionRole((prev) => e.target.value)}
              />
            </Col>
            <Col sm={12} md={3} lg={3}>
              <div className="hstack gap-2 mt-3 justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary fs-14"
                  disabled={isLoading || !String(nameRole).trim()}
                  onClick={() => handleSubmit()}
                >
                  {isLoading ? (
                    <Spinner size="sm me-2"></Spinner>
                  ) : !!id ? (
                    <i className="ri-login-circle-line align-bottom me-2 "></i>
                  ) : (
                    <i className="ri-add-fill align-bottom me-2"></i>
                  )}
                  {id ? t("Button Update Role") : t("Button Create Role")}
                </button>
                <button
                  className="btn btn-soft-secondary fs-14"
                  type="button"
                  color="light"
                  onClick={() => onCloseClick && onCloseClick()}
                  disabled={isLoading}
                >
                  <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                  {t("Button Close")}
                </button>
              </div>
            </Col>
          </Row>
          <div
            style={{
              backgroundColor: "var(--vz-topbar-search-bg)",
              padding: "10px 10px 20px",
              borderRadius: "5px",
            }}
          >
            <div className="form-check form-switch form-switch-primary notification-check mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                value=""
                id={`check-all`}
                checked={isChecked("SUB0")}
                onChange={(e) => handleCheckPermission(e)}
              />
              <label
                className={`form-check-label ${
                  isChecked("SUB0") && "text-primary"
                }`}
                htmlFor={`check-all`}
              >
                {t("Select All Permission")}
              </label>
            </div>
            {permissions?.map((item, sub1: number) => (
              <div key={item?.id}>
                <div className="form-check form-check-primary notification-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id={`check-${sub1}`}
                    checked={isChecked("SUB1", sub1)}
                    onChange={(e) => handleCheckPermission(e, sub1)}
                  />
                  <label className="form-check-label" htmlFor={`check-${sub1}`}>
                    {t(`${item?.name || ""}`)}
                  </label>
                </div>
                {item?.permissions?.map((child: any, sub2: number) => (
                  <div key={child?.id}>
                    <div className="form-check form-check-primary notification-check ms-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id={`check-${sub1}-${sub2}`}
                        checked={isChecked("SUB2", sub1, sub2)}
                        onChange={(e) => handleCheckPermission(e, sub1, sub2)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`check-${sub1}-${sub2}`}
                      >
                        {t(`ROLE_${child?.name || ""}`)}
                      </label>
                    </div>
                    {/* <div className="ms-4">
                                            {child?.permissions?.map((per: any, sub3: number) => (
                                                <div className="form-check form-check-primary notification-check ms-4" key={per?.id}>
                                                    <input className="form-check-input" type="checkbox" value="" checked={isChecked('SUB3', sub1, sub2, per?.id)} id={`check-${sub1}-${sub2}-${sub3}`} onChange={(e) => handleCheckPermission(e, sub1, sub2, sub3)} />
                                                    <label className="form-check-label" htmlFor={`check-${sub1}-${sub2}-${sub3}`}>{per?.display_name}</label>
                                                </div>
                                            ))}
                                        </div> */}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Container>
      </div>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default RoleForm;
