import { createAsyncThunk } from "@reduxjs/toolkit";
import chargeApi from "api/chargeApi";

//Include Both Helper File with needed methods
import { formatQueryParams } from "helpers/format";

export const getCharges = createAsyncThunk(
  "Charge",
  async (params: any = {}) => {
    try {
      const response = await chargeApi.charges(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const postCharge = async (data: any = {}) => {
  try {
    const response = await chargeApi.createCharge(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createPaymentPort = async (data: any = {}) => {
  try {
    const response = await chargeApi.createPayLetter(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const receiptInfo = async (id: number, params: any = {}) => {
  try {
    const response = await chargeApi.receiptInfo(id, params);
    return response;
  } catch (error) {
    return error;
  }
};
