import { t } from "i18next";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";

export default function PopupPaymentFailed({
  detailPayment,
  isOpen,
  handleClose,
}: any) {
  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      size="md"
      scrollable={true}
      toggle={() => handleClose()}
      keyboard={true}
    >
      <ModalHeader toggle={() => handleClose(null)}>
        {t("Payment Status")}
      </ModalHeader>
      <ModalBody className="">
        <div className="row g-4">
          <Col md={12}>
            <div className="p-4 text-center card-body">
              <div className="avatar-lg mx-auto mt-2">
                <div className="avatar-title bg-light text-danger display-3 rounded-circle">
                  <i className="ri-close-circle-fill" />
                </div>
              </div>

              <div className="mt-4 pt-2">
                <h4>{t('Payment Failed!')}</h4>
                <p className="text-muted mx-4">
                  {t('You have failed to make a payment')}.
                </p>
              </div>
            </div>
          </Col>
        </div>
      </ModalBody>
    </Modal>
  );
}
