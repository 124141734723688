import React from "react";
//import Scss
import "./assets/scss/themes.scss";
//imoprt Route
import Route from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <React.Fragment>
      <Route />
      <ToastContainer
        closeButton={false}
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={5}
      />
    </React.Fragment>
  );
}

export default App;
