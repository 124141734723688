import { useBalance } from "components/Hooks/UserHooks";
import { formatNumber } from "helpers/constans";
import { t } from "i18next";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";

export default function PopupPaymentSuccess({
  detailPayment,
  isOpen,
  handleClose,
}: any) {
  const { userBalance } = useBalance();
  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      size="md"
      scrollable={true}
      toggle={() => handleClose()}
      keyboard={true}
    >
      <ModalHeader toggle={() => handleClose(null)}>
        {t("Payment Status")}
      </ModalHeader>
      <ModalBody className="">
        <div className="row g-4">
          <Col md={12}>
            <div className="p-4 text-center card-body">
              <div className="avatar-lg mx-auto mt-2">
                <div className="avatar-title bg-light text-success display-3 rounded-circle">
                  <i className="ri-checkbox-circle-fill" />
                </div>
              </div>
              {detailPayment?.point && (
                <p className="text-primary mt-4 ">
                  +{formatNumber(detailPayment?.point)} {t('Point')}
                </p>
              )}

              <div className="">
                <h4>{t('Payment Success!')}</h4>

                <p className="text-muted mx-4">
                  {t('You have successfully made a payment')}.
                </p>
              </div>
              {userBalance && (
                <div>
                  {t('Your Balance')} :{" "}
                  <span className="text-primary fs-5 fw-semibold">
                    {formatNumber(userBalance?.point)} {t('Point')}
                  </span>
                </div>
              )}
            </div>
          </Col>
        </div>
      </ModalBody>
    </Modal>
  );
}
