import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IUploadImage } from "./types/_news";
const api = new APIClient();

const uploadFileApi = {
  uploadImage(
    dataForm: any,
    onUploadProgress?: (progressEvent: any) => void
  ): Promise<AxiosResponse<IUploadImage[], any>> {
    const url = `upload/files`;
    const formData = new FormData();
    formData.append("files", dataForm?.file);
    return api.post(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-ms-blob-type": "BlockBlob",
      },
      onUploadProgress,
    });
  },
};
export default uploadFileApi;
