import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
var api = new APIClient();

const path = "/reports";
const reportApi = {
  reports(
    params: any
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },
  getSummaryReport(
    id: any
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}/summary/${id}`;
    return api.get(url);
  },

  getSnapshot(
    id: any
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}/snapshot/${id}`;
    return api.get(url);
  },
  exportPDF(id: any): Promise<AxiosResponse<any, any>> {
    const url = `${path}/export/${id}`;
    return api.get(url);
  },
};

export default reportApi;
