import { Editor } from "@tinymce/tinymce-react";
import uploadFileApi from "api/uploadFileApi";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const PLUGINS = [
  "advlist",
  "anchor",
  "autolink",
  "charmap",
  "image",
  "link",
  "lists",
  "media",
  "searchreplace",
  "table",
  "visualblocks", //'inlinecss'
];

const TOOLBAR =
  "blocks | aligncenter alignjustify alignleft alignnone alignright| forecolor  backcolor | bold italic strikethrough underline | table";

interface EditorCustomProps {
  name?: string;
  height?: string;
  plugins?: string[];
  toolbar?: string;
  initialValue?: any;
}

const EditorCustom = (
  {
    name = "",
    height = "calc(100dvh - 270px)",
    plugins = PLUGINS,
    toolbar = TOOLBAR,
    initialValue,
  }: EditorCustomProps,
  ref: any
) => {
  const { t, i18n } = useTranslation();
  const filePickerCallback = (callback: any, value: any, meta: any) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*"); // video/*
    input.onchange = async () => {
      const file: any = input?.files?.[0];
      const maxAllowedSize = 20 * 1024 * 1024;
      if (file?.size > maxAllowedSize) {
        toast(
          `${t("Please choose a file with a size not exceeding 20 MB.")}`,
          CONFIG_OPTION_TOAST_ERROR
        );
        return;
      }
      try {
        console.log("file", file);
        // Upload file
        const response: any = await uploadFileApi.uploadImage({ file: file });
        if (response?.code === 200) {
          // Get the uploaded URL from the response
          const uploadedUrl = response?.data[0]?.url;
          // Invoke the callback with the uploaded URL
          callback(uploadedUrl, {
            alt: file.name,
            title: file.name,
          });
        } else {
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };

    input.click();
  };
  return (
    <React.Fragment>
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        onInit={(evt, editor) => (ref.current = editor)}
        // apiKey={`${process.env.REACT_APP_API_KEY_EDITOR_TINYMCE}`}
        initialValue={initialValue || ""}
        init={{
          height: height,
          menubar: false,
          plugins: plugins,
          toolbar: toolbar,
          content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px } p{ margin-top: 0px; margin-bottom: 0rem; }`,
          branding: false,
          language_url: "/tinymce/langs/ko_KR.js",
          language: `${i18n?.language === "en" ? "en" : "ko_KR"}`,
          language_load: false,
          // I18n: i18n
          // block_unsupported_drop: true,
          // images_upload_credentials: true,
          automatic_uploads: true,
          file_picker_types: "image",
          file_picker_callback: filePickerCallback,
        }}
      />
    </React.Fragment>
  );
};

export default forwardRef(EditorCustom);
