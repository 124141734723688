import { createAsyncThunk } from "@reduxjs/toolkit";
import genderApi from "api/genderApi";
import { formatQueryParams } from "helpers/format";

//Include Both Helper File with needed methods

export const getGenders = createAsyncThunk(
  "Gender",
  async (params: any = {}) => {
    try {
      const response = await genderApi.genders(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  }
);
