import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
var api = new APIClient();

const path = "/analysiss/keywords";
const keywordApi = {
  keywords(
    params: any
  ): Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },
};

export default keywordApi;
