import { createSlice } from "@reduxjs/toolkit";
import { getProfile } from "./thunk";

export interface IState {
  profile: any;
  isProfileLoading: boolean;
  isProfileSuccess: boolean;

  error: any;
  success: any;
  user: any;
}
export const initialState: IState = {
  profile: null,
  isProfileLoading: false,
  isProfileSuccess: false,
  error: {},
  success: "",
  user: {},
};
const ProfileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    profileSuccess(state, action) {
      state.success = action.payload.status;
      state.user = action.payload.data;
    },
    profileError(state, action) {
      state.error = action.payload;
    },
    editProfileChange(state) {
      state = { ...state };
    },
    resetProfileFlagChange(state: any) {
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    //get Packages
    builder.addCase(getProfile.pending, (state: IState, action: any) => {
      state.isProfileLoading = true;
    });
    builder.addCase(getProfile.fulfilled, (state: IState, action: any) => {
      state.profile = action.payload.data;
      state.isProfileSuccess = true;
      state.isProfileLoading = false;
    });
    builder.addCase(getProfile.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isProfileSuccess = false;
      state.isProfileLoading = false;
    });
  },
});

export const {
  profileSuccess,
  profileError,
  editProfileChange,
  resetProfileFlagChange,
} = ProfileSlice.actions;

export default ProfileSlice.reducer;
