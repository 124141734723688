//Include Both Helper File with needed methods
import helpApi from "api/helpApi";
import { formatQueryParams } from "helpers/format";

export const getHelpList = async (params: any = {}) => {
  try {
    const response = await helpApi.helps(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const postHelp = async (data: any = {}) => {
  try {
    const response = await helpApi.createHelp(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putHelp = async (id: string | number, data: any = {}) => {
  try {
    const response = await helpApi.putHelp(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteHelp = async (id: string | number, data: any = {}) => {
  try {
    const response = await helpApi.deleteHelp(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
