import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Progress,
  Row,
  Spinner,
} from "reactstrap";

import BreadCrumb from "components/Common/BreadCrumb";
import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import moment from "moment";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getReports as onGetReports } from "store/thunks";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import TooltipCustom from "components/Common/TooltipCustom";
import { Link } from "react-router-dom";
import SummaryReportModal from "./component/SummaryReportModal";
import NewsSnapshotModal from "./component/NewsSnapshotModal";
import { toast } from "react-toastify";
import reportApi from "api/reportApi";
import {
  CONFIG_OPTION_TOAST_NORMAL,
  CONFIG_OPTION_TOAST_ERROR,
} from "common/toast";
import { formatNumber } from "helpers/constans";

const typeQuery = {
  keyword_count: StringParam,
};
function ReportList() {
  const { t, i18n } = useTranslation();
  const [itemReport, setItemReport] = useState<any>();
  const [itemSnapshot, setItemSnapshot] = useState<any>();
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    keyword: StringParam,
    end_date: withDefault(StringParam, ""),
    start_date: withDefault(StringParam, ""),
    advertiser_id: StringParam,
    ...typeQuery,
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date
      ? moment(query?.start_date || "", "Y-MM-DD").toDate()
      : "",
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : "",
  ]);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState("");
  const [advertiserSearch, setAdvertiserSearch] = useState<any>();

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Report;

  const userProperties = createSelector(selectLayoutState, (state) => ({
    reports: state.reports,
    isReportSuccess: state.isReportSuccess,
    isReportLoading: state.isReportLoading,
    error: state.error,
  }));

  // Inside your component
  const { reports, isReportLoading } = useSelector(userProperties);
  const handleShowReport = (item: any) => {
    setItemReport(item);
  };

  const handleShowSnapshot = (item: any) => {
    setItemSnapshot(item);
  };

  const handleExportReportToPDF = async (item: any) => {
    setIsLoadingExport(true);
    try {
      const res: any = await reportApi.exportPDF(item?.id);
      if (res?.code === 200) {
        toast.success(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        window.open(res?.data?.url, "_blank");
      } else {
        toast.error(`${res?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      }
      setIsLoadingExport(false);
    } catch (error: any) {
      setIsLoadingExport(false);
      toast.error(
        `${error?.message || error || ""}`,
        CONFIG_OPTION_TOAST_ERROR
      );
    }
  };

  useEffect(() => {
    dispatch(onGetReports(query));
  }, [dispatch, query]);

  const columns = useMemo(
    () => [
      {
        Header: t("Campaign Name"),
        accessor: "name",
        filterable: false,
        sortable: false,
      },
      {
        Header: t("News Title"),
        accessor: "news_title",
        filterable: false,
        sortable: false,
      },

      {
        Header: t("PV"),
        accessor: "pv",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="text-center">{formatNumber(cell?.value)}</div>
        ),
      },
      {
        Header: t("UV"),
        accessor: "uv",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="text-center">{formatNumber(cell?.value)}</div>
        ),
      },
      {
        Header: t("Published Date"),
        accessor: "published_at",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Summary Report"),
        accessor: "action_summary",
        filterable: false,
        sortable: false,
        thWidth: 150,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="text-center">
              <li className="list-inline-item" id={`detail-md-${item?.id}`}>
                <Link
                  className="btn btn-sm btn-success edit-item-btn"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowReport(item);
                  }}
                >
                  <i className="ri-eye-fill align-bottom "></i> {t("Summary")}
                </Link>
              </li>
            </div>
          );
        },
      },
      {
        Header: t("News Snapshot"),
        accessor: "action_snapshot",
        filterable: false,
        sortable: false,
        thWidth: 130,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="text-center">
              <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                <Link
                  className="btn btn-sm btn-success edit-item-btn"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleShowSnapshot(item);
                  }}
                >
                  <i className=" ri-image-fill align-bottom"></i> {t("Snapshot")}
                </Link>
              </li>
            </div>
          );
        },
      },
      {
        Header: t("Export"),
        accessor: "action_export",
        filterable: false,
        sortable: false,
        thWidth: 110,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="text-center">
              <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                <Link
                  className="btn btn-sm btn-success edit-item-btn"
                  to="#"
                  onClick={(e) => {
                    handleExportReportToPDF(item);
                  }}
                >
                  <i className="ri-file-download-line align-bottom"></i> {t("Export")}
                </Link>
              </li>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    setQuery({
      ...query,
      keyword: keyword,
      page: 1,
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      advertiser_id: advertiserSearch?.value,
    });
  };
  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };
  const resetData = () => {
    setQuery(
      {
        keyword: undefined,
        advertiser_id: undefined,
        start_date: undefined,
        end_date: undefined,
      },
      "push"
    );
    setKeyword("");
    setAdvertiserSearch(null);
    setDateSearch(["", ""]);
  };
  useEffect(() => {
    document.title = `${t("Report")} | Pr Console`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Report")} pageTitle={t("Report")} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0 ">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2">
                    <Card
                      className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0 d-none d-md-block"
                      style={{ width: "200px" }}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                            <span className="counter-value text-primary">
                              {isReportLoading ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                <CountUp
                                  start={0}
                                  end={reports?.pagination?.total || 0}
                                  duration={1}
                                />
                              )}
                            </span>
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <div className="align-items-end ">
                        <Row className="g-4 align-items-center">
                          <Col md={4} lg={3}>
                            <LabelWrapper
                              label={t("Search by Campaign, News title")}
                              isShow={keyword ? true : false}
                            >
                              <div className="search-box">
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t(
                                    "Search by Campaign, News title"
                                  )}...`}
                                  value={keyword}
                                  onChange={(e) => setKeyword(e.target.value)}
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </LabelWrapper>
                          </Col>
                          <Col
                            md={4}
                            lg={3}
                            className="date-picker-wrapper-custom"
                          >
                            <LabelWrapper
                              label={t("version_date")}
                              isShow={!!startDate || !!endDate}
                            >
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col lg={4} md={6} className="hstack gap-1">
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={isReportLoading}
                              onClick={handleSearch}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={reports?.list?.length ? reports?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={reports?.pagination?.total}
                      customPageCount={Math.ceil(
                        reports?.pagination?.total / reports?.pagination?.limit
                      )}
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      handleChangePage={handleChangePage}
                      isLoading={isReportLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <SummaryReportModal
          itemReport={itemReport}
          handleClose={() => setItemReport(null)}
        />
        <NewsSnapshotModal
          itemSnapshot={itemSnapshot}
          handleClose={() => setItemSnapshot(null)}
        />
        {isLoadingExport && (
          <div
            className="position-absolute start-0 top-0 d-flex justify-content-center align-items-center"
            id="loading"
            style={{ height: "100vh", width: "100vw", zIndex: 10000 }}
          >
            <div
              className="d-flex flex-column p-2 justify-content-center align-items-center border rounded-2"
              style={{ height: 100, width: 400, backgroundColor: "#868686b3" }}
            >
              <p className="text-medium mb-0 text-white">{t("Exporting")}...</p>
              <Progress animated className="my-3 w-100" value={100} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default ReportList;
