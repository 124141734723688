import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownStatusNews from "components/Common/DropdownStatusNews";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { STATUS_INQUIRY_OPTIONS } from "helpers/constans";
import moment from "moment";
import CountUp from "react-countup";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteInquiry, getInquiryList } from "store/inquiry/thunk";
import ModalForm from "./components/ModalForm";
import ModalFormCreate from "./components/ModalFormCreate";
import BreadCrumb from "components/Common/BreadCrumb";

const typeQuery = {
  keyword_count: StringParam,
};
const HelpList = () => {
  const { t, i18n } = useTranslation();
  const STATUS_INQUIRY_OPTIONS_LANG = STATUS_INQUIRY_OPTIONS?.map(
    (item: any) => ({ value: item?.value, label: t(item?.label) })
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<any>();

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    keyword: StringParam,
    end_date: withDefault(StringParam, ""),
    start_date: withDefault(StringParam, ""),
    status: StringParam,
    ...typeQuery,
  });

  const [statusSearch, setStatusSearch] = useState<any | null>(
    STATUS_INQUIRY_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.status)
    )[0]
  );

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.start_date
      ? moment(query?.start_date || "", "Y-MM-DD").toDate()
      : "",
    query?.end_date ? moment(query?.end_date || "", "Y-MM-DD").toDate() : "",
  ]);
  const [startDate, endDate] = dateSearch;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<any | null>(
    null
  );
  const [keyword, setKeyword] = useState("");
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [userEdit, setUserEdit] = useState<(any & { isCopy?: boolean }) | null>(
    null
  );
  const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const getInquiries = async () => {
    try {
      setIsLoading(true);
      const res: any = await getInquiryList(query);
      if (res?.code === 200) {
        setDataList(res?.data);
      } else {
        toast.error(`${res?.error || ""}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast.error(`${error || ""}`, CONFIG_OPTION_TOAST_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDelete = (item: any) => {
    setInitialValuesDefault((_prev: any) => item);
    setIsConfirm((_prev) => true);
  };

  const handleShowDetail = (item: any) => {
    setInitialValuesDefault((_prev: any) => item);
    setIsOpen((_prev) => true);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Status"),
        accessor: "status_name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const status = () => {
            switch (value?.status) {
              case "pending":
                return "warning";
              case "in_progress":
                return "primary";
              case "completed":
                return "success";
              default:
                return "danger";
            }
          };
          return (
            <div style={{ minWidth: "60px" }}>
              <span className={`badge bg-${status()}`}>{`${t(
                `${value?.status}`
              )}`}</span>
            </div>
          );
        },
      },
      {
        Header: t("Title"),
        accessor: "title",
        filterable: false,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          return (
            <div
              onClick={() => handleShowDetail(value)}
              className="text-primary cursor-pointer"
            >
              {value?.title}
            </div>
          );
        },
      },
      {
        Header: t("Campaign"),
        accessor: "campaign",
        filterable: false,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          return <div>{value?.campaign?.name}</div>;
        },
      },

      {
        Header: t("Content"),
        accessor: "content",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          return (
            <div dangerouslySetInnerHTML={{ __html: value?.content }}></div>
          );
        },
      },

      {
        Header: t("Attachment"),
        accessor: "attachments",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          return value?.attachments?.map((item: any) => (
            <img
              className="cursor-pointer"
              src={item?.url}
              alt=""
              style={{ maxWidth: 70, maxHeight: 70 }}
            ></img>
          ));
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },

      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              {
                <TooltipCustom
                  title={t("Button Delete Inquiry")}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                    <Link
                      className="btn btn-sm btn-soft-secondary edit-item-btn"
                      to="#"
                      onClick={(e) => {
                        handleConfirmDelete(item);
                      }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              }
            </ul>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [i18n?.language]
  );

  // Search

  const handleSearch = () => {
    setQuery({
      ...query,
      keyword: keyword,
      page: 1,
      start_date: startDate
        ? moment(new Date(startDate)).format("Y-MM-DD")
        : "",
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      status: statusSearch?.value,
    });
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    //eslint-disable-next-line
  }, []);

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev: any) => null);
    setIsConfirm((_prev) => false);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteInquiry(initialValuesDefault?.id);
      if (response?.code === 200) {
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev: any) => null);
        refreshData();
        toast.success(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast.error(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast.error(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const resetData = () => {
    setQuery(
      {
        keyword: undefined,
        status: undefined,
        start_date: undefined,
        end_date: undefined,
      },
      "push"
    );
    setKeyword("");
    setStatusSearch(null);
    setDateSearch(["", ""]);
  };

  const refreshData = () => {
    getInquiries();
  };

  const handleCreate = () => {
    setIsOpenCreate(true);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    getInquiries();
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    document.title = `${t("Inquiry")} | PR Console`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Inquiry")} pageTitle={t("Customer Service")} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0 ">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2">
                    <Card
                      className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0 d-none d-md-block"
                      style={{ width: "200px" }}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                              {t("Total")}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                            <span className="counter-value text-primary">
                              {isLoading ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                <CountUp
                                  start={0}
                                  end={dataList?.pagination?.total || 0}
                                  duration={1}
                                />
                              )}
                            </span>
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <div className="align-items-end ">
                        <Row className="align-items-end justify-content-end mb-2">
                          {" "}
                          <Col
                            lg={3}
                            md={12}
                            className="md-text-start text-end mb-xl-0 mb-2 d-block"
                          >
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={handleCreate}
                            >
                              <i className="ri-add-fill align-bottom me-1"></i>{" "}
                              {t("Create Inquiry")}
                            </button>
                          </Col>
                        </Row>
                        <Row className="g-2 align-items-center">
                          <Col md={12} lg={3}>
                            <LabelWrapper
                              label={t("Campaign, Title")}
                              isShow={keyword ? true : false}
                            >
                              <div className="search-box">
                                <Input
                                  type="text"
                                  className="form-control search"
                                  placeholder={`${t(
                                    "Search by Campaign, Title"
                                  )}...`}
                                  value={keyword}
                                  onChange={(e) => setKeyword(e.target.value)}
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </LabelWrapper>
                          </Col>
                          <Col md={12} lg={3}>
                            <LabelWrapper
                              label={t("Status")}
                              isShow={statusSearch?.value ? true : false}
                            >
                              <DropdownStatusNews
                                name="status"
                                dataList={STATUS_INQUIRY_OPTIONS_LANG || []}
                                placeholder={`${t("Status")}...`}
                                className="search-filter-category-type"
                                classNamePrefix="name-prefix"
                                initialValue={statusSearch || null}
                                onChangeSelect={(e: any) => setStatusSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{
                                  label: t("All Status"),
                                  value: "",
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col
                            md={12}
                            lg={3}
                            className="date-picker-wrapper-custom"
                          >
                            <LabelWrapper
                              label={t("version_date")}
                              isShow={!!dateSearch}
                            >
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col lg={3} md={12} className="hstack gap-1">
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={isLoading}
                              onClick={handleSearch}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={dataList?.list?.length ? dataList?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={dataList?.pagination?.total}
                      customPageCount={Math.ceil(
                        dataList?.pagination?.total /
                        dataList?.pagination?.limit
                      )}
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      handleChangePage={handleChangePage}
                      isLoading={isLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ModalForm
          onCloseClick={() => {
            setIsOpen(false);
            setUserEdit(null);
          }}
          initialValuesDefault={initialValuesDefault || userEdit}
          show={isOpen}
          refreshData={refreshData}
        />
        <ModalFormCreate
          onCloseClick={() => {
            setIsOpenCreate(false);
            setUserEdit(null);
          }}
          show={isOpenCreate}
          refreshData={refreshData}
        />
        <ModalConfirm
          header={t("Delete Inquiry")}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div>
    </React.Fragment>
  );
};

export default HelpList;
