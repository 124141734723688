import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/auth";
const authApi = {
  login(data: any) {
    const url = `${path}/login/advertiser`;
    return api.post(url, data);
  },
  refresh(data: any) {
    const url = `${path}/refresh_token`;
    return api.post(url, data);
  },
  logout() {
    const url = `${path}/logout`;
    return api.post(url, {});
  },
  permissions(params: any = {}) {
    const url = `/permissions/current`;
    return api.get(url, params);
  },
  profile(id: string | number, params: any = {}) {
    const url = `/advertisers/profile`;
    return api.get(url);
  },
  forgotPassword(data: any) {
    const url = `${path}/forgot_password`;
    return api.post(url, data);
  },
  resetPassword(data: any) {
    const url = `${path}/reset_password`;
    return api.post(url, data);
  },
  verifyResetToken(data: any) {
    const url = `${path}/reset_password/verify_token`;
    return api.post(url, data);
  },
};
export default authApi;
