const ROLES_FOR_APP:any = {    
    DASHBOARD_VIEW: "view",

    CAMPAIGN_LIST: "campaign_list",
    CAMPAIGN_CREATE: "campaign_create",
    CAMPAIGN_UPDATE: "campaign_update",
    CAMPAIGN_DELETE: "campaign_remove",
    CAMPAIGN_SET_CAMPAIGN_SPORT: "campaign_set_campaign_sports",
    CAMPAIGN_COPY: "campaign_copy",
    CAMPAIGN_SAVE: "campaign_create",
    CAMPAIGN_DOWNLOAD: "campaign_list",

    CHARGE_LIST: "charge_list",
    CHARGE_HISTORY_LIST: "charge_history_list",
    PAYMENT_HISTORY_LIST: "payment_history_list",

    ANALYSIS_LIST: "analysis_list",

    REPORT_LIST: "report_list",
    EXPORT_LIST: "export_list",
    SUMMARY_REPORT_LIST: "summary_report_list",
    NEWS_SNAPSHOTS_LIST: "news_snapshots_list",

    HELP_LIST: "help_list",
    INQUIRY_LIST: "inquiry_list",
    
    ROLE_LIST: "role_list",
    ROLE_CREATE: "role_create",
    ROLE_UPDATE: "role_update",
    ROLE_DELETE: "role_remove",

    USER_LIST: "user_list",
    USER_CREATE: "user_create",
    USER_UPDATE: "user_update",
    USER_DELETE: "user_remove",

    SSO_LIST:"sso_list",
    SSO_MESSAGEHUB: 'sso_messagehub',

    CONFIGURATION_LIST: "campaign_list",
    CONFIGURATION_CREATE: "campaign_create",
    CONFIGURATION_UPDATE: "campaign_update",
    CONFIGURATION_DELETE: "campaign_remove",

};

const isHavePermissionRole = (keyRoles: string | string[], permissions: {id: string | number, name: string}[] = []):boolean => {
    const roles =  typeof keyRoles === 'string' ? [keyRoles] : keyRoles;
    return  true; // permissions?.some(item => roles?.includes(item.name));
};

export { ROLES_FOR_APP, isHavePermissionRole };