import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getReports } from "./thunk";

export interface IState {
  reports: (ResponseData<any[]> & PaginationResponse) | null;
  isReportLoading: boolean;
  isReportSuccess: boolean;

  allReport: ResponseData<any[]> | null;

  error: any;
}

export const initialState: IState = {
  reports: null,
  isReportLoading: false,
  isReportSuccess: false,
  allReport: null,
  error: {},
};

const packageSlice = createSlice({
  name: "Report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Packages
    builder.addCase(getReports.pending, (state: IState, action: any) => {
      state.isReportLoading = true;
    });
    builder.addCase(getReports.fulfilled, (state: IState, action: any) => {
      state.reports = action.payload.data;
      state.isReportSuccess = true;
      state.isReportLoading = false;
      state.allReport = action.payload.data?.list;
    });
    builder.addCase(getReports.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isReportSuccess = false;
      state.isReportLoading = false;
    });
  },
});

export default packageSlice.reducer;
