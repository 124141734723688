import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/allRoutes";

const Navdata = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { userPermissions } = useRole();

  const [menuActive, setMenuActive] = useState<string>("DASHBOARD");

  // eslint-disable-next-line
  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
  }, [history]);

  const menuItems: any = [
    {
      id: "dashboard",
      label: t("Dashboards"),
      icon: <FeatherIcon icon="home" className="icon-dual" />,
      link: ROUTES.DASHBOARD,
      click: function (e: any) {
        setMenuActive((prev) => "DASHBOARD");
        setIsCurrentState((prev) => "Dashboard");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "DASHBOARD",
      isShow: isHavePermissionRole(
        ROLES_FOR_APP.DASHBOARD_VIEW,
        userPermissions
      ),
      // subItems: []
    },
    {
      id: "multilevel",
      label: t("Campaign"),
      icon: <FeatherIcon icon="grid" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "CAMPAIGN");
        setIsCurrentState((prev) => "Campaign");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "CAMPAIGN",
      isShow: isHavePermissionRole(
        [ROLES_FOR_APP.CAMPAIGN_LIST, ROLES_FOR_APP.CAMPAIGN_CREATE],
        userPermissions
      ),
      subItems: [
        {
          id: "campaign-create",
          label: t("Campaign Create"),
          link: ROUTES.CAMPAIGN_CREATE,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_CREATE,
            userPermissions
          ),
        },
        {
          id: "Campaign",
          label: t("Campaign"),
          link: ROUTES.CAMPAIGN_LIST,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_LIST,
            userPermissions
          ),
        },
      ],
    },
    {
      id: "multilevel4",
      label: t("Point"),
      icon: <FeatherIcon icon="credit-card" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "POINT");
        setIsCurrentState((prev) => "Point");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "POINT",
      isShow: isHavePermissionRole(
        [
          ROLES_FOR_APP.CHARGE_LIST,
          ROLES_FOR_APP.CHARGE_HISTORY_LIST,
          ROLES_FOR_APP.PAYMENT_HISTORY_LIST,
        ],
        userPermissions
      ),
      subItems: [
        {
          id: "charge",
          label: t("Charge"),
          link: ROUTES.CHARGE,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CHARGE_LIST,
            userPermissions
          ),
        },
        {
          id: "charge-history",
          label: t("Charge History"),
          link: ROUTES.CHARGE_HISTORY,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CHARGE_HISTORY_LIST,
            userPermissions
          ),
        },
        {
          id: "payment-history",
          label: t("Payment History"),
          link: ROUTES.PAYMENT_HISTORY,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.PAYMENT_HISTORY_LIST,
            userPermissions
          ),
        },
      ],
    },
    {
      id: "multilevel2",
      label: t("Analysis"),
      icon: <FeatherIcon icon="pie-chart" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "ANALYSIS");
        setIsCurrentState((prev) => "Analysis");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "ANALYSIS",
      isShow: isHavePermissionRole(
        [ROLES_FOR_APP.ANALYSIS_LIST],
        userPermissions
      ),
      subItems: [
        {
          id: "gender-age-analysis",
          label: t("Gender & Age Analysis"),
          link: ROUTES.GENDER_AGE_ANALYSIS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.ANALYSIS_LIST,
            userPermissions
          ),
        },
        {
          id: "keyword-analysis",
          label: t("Keyword Analysis"),
          link: ROUTES.KEYWORD_ANALYSIS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.ANALYSIS_LIST,
            userPermissions
          ),
        },
      ],
    },
    {
      id: "multilevel2",
      label: t("Report"),
      icon: <FeatherIcon icon="file-text" className="icon-dual" />,
      link: ROUTES.REPORT,
      click: function (e: any) {
        setMenuActive((prev) => "REPORT");
        setIsCurrentState((prev) => "Report");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "REPORT",
      isShow: isHavePermissionRole(
        [ROLES_FOR_APP.REPORT_LIST, ROLES_FOR_APP.EXPORT_LIST],
        userPermissions
      ),
      // subItems: [
      //   {
      //     id: "report",
      //     label: t("Report"),
      //     link: ROUTES.REPORT,
      //     isShow: isHavePermissionRole(
      //       ROLES_FOR_APP.REPORT_LIST,
      //       userPermissions
      //     ),
      //   },
      //   // {
      //   //     id: "summary-report",
      //   //     label: t('Summary Report'),
      //   //     link: ROUTES.SUMMARY_REPORT,
      //   //     isShow: isHavePermissionRole(ROLES_FOR_APP.SUMMARY_REPORT_LIST, userPermissions),
      //   // },
      //   // {
      //   //     id: "news-snapshots",
      //   //     label: t('News Snapshots'),
      //   //     link: ROUTES.NEWS_SNAPSHOTS,
      //   //     isShow: isHavePermissionRole(ROLES_FOR_APP.NEWS_SNAPSHOTS_LIST, userPermissions),
      //   // },
      //   // {
      //   //   id: "export",
      //   //   label: t("Export"),
      //   //   link: ROUTES.EXPORT,
      //   //   isShow: isHavePermissionRole(
      //   //     ROLES_FOR_APP.EXPORT_LIST,
      //   //     userPermissions
      //   //   ),
      //   // },
      // ],
    },
    {
      id: "multilevel3",
      label: t("Customer Service"),
      icon: <FeatherIcon icon="headphones" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "CUSTOMER_SERVICE");
        setIsCurrentState((prev) => "CustomerService");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "CUSTOMER_SERVICE",
      isShow: isHavePermissionRole(
        [ROLES_FOR_APP.HELP_LIST, ROLES_FOR_APP.INQUIRY_LIST],
        userPermissions
      ),
      subItems: [
        {
          id: "help",
          label: t("Help"),
          link: ROUTES.HELP,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.HELP_LIST,
            userPermissions
          ),
        },
        {
          id: "inquiry",
          label: t("Inquiry"),
          link: ROUTES.INQUIRY,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.INQUIRY_LIST,
            userPermissions
          ),
        },
      ],
    },

    // {
    //     id: "logout",
    //     label: "Logout",
    //     icon: <FeatherIcon icon="log-out" className="icon-log-out" />,
    //     link: "/logout",
    //     stateVariables: isDashboard,
    //     click: function (e: any) {
    //         e.preventDefault();
    //         setIsDashboard(!isDashboard);
    //         setIsCurrentState( (prev) =>  'Dashboard');
    //         updateIconSidebar(e);
    //     },
    // },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
