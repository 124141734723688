import reportApi from "api/reportApi";
import {
  CONFIG_OPTION_TOAST_NORMAL,
  CONFIG_OPTION_TOAST_ERROR,
} from "common/toast";
import { t } from "i18next";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import { getSummaryReport } from "store/thunks";
export default function SummaryReportModal({
  itemReport,
  handleClose,
  className = "",
  title = "",
  enabledDataLabels = false,
}: any) {
  const [isLoadingGetReport, setIsLoadingGetReport] = useState(false);
  const [medias, setMedias] = useState<any>([]);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const options = (
    category: any,
    color: string,
    unitLabel = "",
    type = "bar"
  ) => {
    return {
      chart: {
        type: type,
        height: 341,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "65%",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
        pie: {
          dataLabels: {
            offset: -10,
          },
        },
      },
      stroke: {
        show: true,
        width: 1.2,
      },
      xaxis: {
        categories: category,
        labels: {
          formatter: function (value: any, index: any) {
            return value + ` ${unitLabel}`;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value: any, index: any) {
            return type === "pie" ? category[index] : "" + value + "%";
          },
        },
        min: 0,
        max: 100,
        stepSize: 10,
        tickAmount: 4,
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any, opts: any) {
          if (type === "pie") {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + "%"];
          }
          return val + "%";
        },
        offsetY: -22,
        style: {
          fontSize: "12px",
          colors: [color],
        },
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
        fontWeight: 500,
        offsetX: 0,
        offsetY: -14,
        itemMargin: {
          horizontal: 8,
          vertical: 0,
        },
        markers: {
          width: 10,
          height: 10,
        },
      },
      colors: type === "pie" ? ["#1eaae9", "#45996a", "#646fb0"] : [color],
    };
  };
  const getReport = async () => {
    try {
      setIsLoadingGetReport(true);
      const res: any = await getSummaryReport(itemReport?.id);
      if (res?.code === 200) {
        const mediasData = res?.data?.medias;
        const newData = mediasData?.map((item: any) => {
          const { gender_age, keywords } = item;
          const { age, gender } = gender_age;
          const { referrer_ratio } = keywords;
          const data = Object.values(age);
          const category = Object.keys(age).map(Number);

          const formattedDataAge = {
            series: [{ data: data }],
            category: category,
          };

          const dataGender = Object.values(gender);
          const categoryGender = Object.keys(gender).map(String);
          const formattedDataGender = {
            series: [{ data: dataGender }],
            category: categoryGender,
          };

          const dataReferrer = Object.values(referrer_ratio);
          const categoryReferrer = Object.keys(referrer_ratio).map(String);
          const formattedDataReferrer = {
            series: dataReferrer,
            category: categoryReferrer,
          };
          return {
            ...item,
            formattedDataAge: formattedDataAge,
            formattedDataGender: formattedDataGender,
            formattedDataReferrer: formattedDataReferrer,
          };
        });
        setMedias(newData);
      }
      setIsLoadingGetReport(false);
    } catch (error) {
      setIsLoadingGetReport(false);
    }
  };

  const handleExportReportToPDF = async (item: any) => {
    setIsLoadingExport(true);
    try {
      const res: any = await reportApi.exportPDF(item?.id);
      if (res?.code === 200) {
        toast.success(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        window.open(res?.data?.url, "_blank");
      } else {
        toast.error(`${res?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      }
      setIsLoadingExport(false);
    } catch (error: any) {
      setIsLoadingExport(false);
      toast.error(
        `${error?.message || error || ""}`,
        CONFIG_OPTION_TOAST_ERROR
      );
    }
  };

  useEffect(() => {
    if (itemReport?.id) {
      getReport();
    }
    // eslint-disable-next-line
  }, [itemReport]);
  return (
    <Modal
      isOpen={!!itemReport}
      centered={true}
      size="lg"
      scrollable={true}
      toggle={() => {
        if (isLoadingExport) return;
        handleClose();
      }}
      keyboard={true}
      contentClassName={"h-100"}
    >
      <ModalHeader
        toggle={() => {
          handleClose();
        }}
        className="position-relative"
      >
        {t("Summary Report")}
        <button
          onClick={() => handleExportReportToPDF(itemReport)}
          type="button"
          className="btn position-absolute float-end btn-soft-primary "
          style={{ right: 60, top: 20 }}
        >
          <i className="ri-download-2-fill align-bottom me-2"></i>{t('Export')}
        </button>
      </ModalHeader>
      <ModalBody className="">
        {isLoadingGetReport ? (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <Spinner size="lg" className="text-primary"></Spinner>
          </div>
        ) : (
          medias?.map((item: any) => (
            <Card className="p-3 gap-3">
              <Row>
                <h4 className="card-title mb-0 flex-grow-1">
                  {t("Media")}:{" "}
                  <span className="text-primary">{item?.media_name}</span>
                </h4>
                <p className=" mb-0 flex-grow-1 text-muted mt-2">
                  {t("Published at")}: {item?.published_at}
                </p>
              </Row>
              <Row>
                <Col xxl={6}>
                  <Card className="mb-0">
                    <div className="align-items-center d-flex card-header">
                      <h4 className="card-title mb-0 flex-grow-1">
                        {t("Age Analysis")}
                      </h4>
                    </div>
                    <CardBody className="pb-0">
                      <ReactApexChart
                        type="bar"
                        options={options(
                          item?.formattedDataAge?.category,
                          "#009688",
                          "age"
                        )}
                        series={item?.formattedDataAge?.series}
                        height="250"
                        className={`apex-charts ${className}`}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col xxl={6}>
                  <Card className="mb-0">
                    <div className="align-items-center d-flex card-header">
                      <h4 className="card-title mb-0 flex-grow-1">
                        {t("Gender Analysis")}
                      </h4>
                    </div>
                    <CardBody className="pb-0">
                      <ReactApexChart
                        type="bar"
                        options={options(
                          item?.formattedDataGender?.category,
                          "#3d73a9"
                        )}
                        series={item?.formattedDataGender?.series}
                        height="250"
                        className={`apex-charts ${className}`}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xxl={6}>
                  <Card className="mb-0">
                    <div className="align-items-center d-flex card-header">
                      <h4 className="card-title mb-0 flex-grow-1">
                        {t("Referrer Rate Analysis")}
                      </h4>
                    </div>
                    <CardBody className="pb-0">
                      <ReactApexChart
                        type="pie"
                        options={options(
                          item?.formattedDataReferrer?.category,
                          "#3d73a9",
                          "",
                          "pie"
                        )}
                        series={item?.formattedDataReferrer?.series}
                        height="250"
                        className={`apex-charts ${className}`}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col xxl={6}>
                  <Card className="h-100 mb-0">
                    <div className="align-items-center d-flex card-header">
                      <h4 className="card-title mb-0 flex-grow-1">
                        {t("Keyword Analysis")}
                      </h4>
                    </div>
                    <CardBody className="pb-0">
                      <table className="table-borderless mb-0 table">
                        <tbody>
                          {item &&
                            item?.keywords?.inflow_keywords?.map(
                              (keyword: any) => (
                                <span className="tag-style" key={keyword}>
                                  {keyword}
                                </span>
                              )
                            )}
                          {medias?.length <= 0 && <>{t("No data")}</>}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Card>
          ))
        )}
      </ModalBody>
      {isLoadingExport && (
        <div
          className="position-absolute start-0 top-0 d-flex justify-content-center align-items-center"
          id="loading"
          style={{ height: "100%", width: "100%", zIndex: 10000 }}
        >
          <div
            className="d-flex flex-column p-2 justify-content-center align-items-center border rounded-2"
            style={{ height: 100, width: 400, backgroundColor: "#868686b3" }}
          >
            <p className="text-medium mb-0 text-white">{t("Exporting")}...</p>
            <Progress animated className="my-3 w-100" value={100} />
          </div>
        </div>
      )}
    </Modal>
  );
}
